import React, { useCallback, useContext, useState } from "react";
import styles from "./toggleLanguage.module.scss";
import { LocaleContext } from "../IntlWrapper";

import useWidth from "../../hooks/useWidth";

const ToggleLanguage = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [activeL, setActiveL] = useState(false);
  let width = useWidth();

  const handleLocaleChange = useCallback(({ target }) => setLocale(target.id), [
    setLocale
  ]);

  const handleLocaleChangeMob = useCallback(
    e => {
      e.stopPropagation();
      setLocale(e.target.id);
      setActiveL(false);
    },
    [setLocale, setActiveL]
  );

  const toggleLengList = useCallback(() => {
    setActiveL(true);
  }, []);

  // ===============================render desctop
  const renderDesctop = () => {
    return (
      <div className={styles.lengContainer}>
        <div
          id="ch-MA"
          onClick={handleLocaleChange}
          className={
            locale === "ch-MA"
              ? styles.lengContainer__item + " " + styles.lengContainer__active
              : styles.lengContainer__item
          }
        >
          <img src="/img/china.png" alt="china" />
          <span>中文</span>
        </div>

        <span className={styles.lengContainer__line}></span>

        <div
          id="en-CA"
          onClick={handleLocaleChange}
          className={
            locale === "en-CA"
              ? styles.lengContainer__item + " " + styles.lengContainer__active
              : styles.lengContainer__item
          }
        >
          <img src="/img/canada.png" alt="canada" />
          <span>English</span>
        </div>
      </div>
    );
  };
  // ===============================render mobile
  const renderMobile = () => {
    return (
      <div className={styles.leng_m} onClick={toggleLengList}>
        {locale === "en-CA" ? (
          <div className={styles.leng_m_item}>
            <img src="/img/canada.png" alt="canada" />
            <span>English</span>
          </div>
        ) : (
          <div className={styles.leng_m_item}>
            <img src="/img/china.png" alt="china" />
            <span>中文</span>
          </div>
        )}
        {activeL ? (
          <div className={styles.leng_m_ch}>
            <div
              className={styles.leng_m_item}
              id="ch-MA"
              onClick={handleLocaleChangeMob}
            >
              <img src="/img/china.png" alt="china" />
              <span>中文</span>
            </div>
            <div
              className={styles.leng_m_item}
              id="en-CA"
              onClick={handleLocaleChangeMob}
            >
              <img src="/img/canada.png" alt="canada" />
              <span>English</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return <>{width >= 991 ? renderDesctop() : renderMobile()}</>;
};

export default ToggleLanguage;
