import React from "react";
import styles from "./logo.module.scss";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <img src="/img/logo.png" alt="logo" />
    </div>
  );
};

export default Logo;
