export const PATH = process.env.NODE_ENV === "development" ? "/" : "/";
export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = `https://api-dev.iwaretravel.ca/`;

export const PROVINCES = [
  { name: "Newfoundland and Labrador", code: "NL" },
  // { name: "Prince Edward Island", code: "PE" },
  { name: "Nova Scotia", code: "NS" },
  // { name: "New Brunswick", code: "NB" },
  // { name: "Quebec", code: "QC" },
  { name: "Ontario", code: "ON" },
  // { name: "Manitoba", code: "MB" },
  { name: "Saskatchewan", code: "SK" },
  { name: "Alberta", code: "AB" },
  { name: "British Columbia", code: "BC" },
  // { name: "Yukon", code: "YT" },
  // { name: "Northwest Territories", code: "NT" },
  // { name: "Nunavut", code: "NU" }
];


