import ACTION from "../../constants/constants";

export const beginApiCall = () => ({
  type: ACTION.BEGIN_API_CALL
});

export const apiCallError = err => ({
  type: ACTION.API_CALL_ERROR,
  err
});

export const apiCallEnd = () => ({
  type: ACTION.API_CALL_END
});
