import ACTIONS from "../../constants/constants";

export const addModalTemplate = (active, props) => ({
  type: ACTIONS.ADD_MODAL_CONTENT,
  active,
  props,
});

export const clearModalTemplate = () => ({
  type: ACTIONS.CLEAR_MODAL_CONTENT,
});

export const setTugoEligible = () => ({
  type: ACTIONS.TUGO_ELIGIBLE,
});
export const setTugoNotEligible = () => ({
  type: ACTIONS.TUGO_NOT_ELIGIBLE,
});
