import React from "react";
import styles from "./policy.module.scss";

const TugoEligibility = ({ content, onClick, handleClose, setNotEligible }) => {
  const onChange = () => {
    handleClose();
    onClick();
  };
  const onNotEligible = () => {
    handleClose();
    setNotEligible();
  };

  return (
    <div style={{ fontSize: "1em" }} className={styles.modal_container}>
      <div className={styles.modal_logo_container}>
        <img
          src={"./img/policy/tugo.png"}
          alt="logo"
          className={styles.policy_logo}
        />
      </div>
      <div
        style={{
          margin: "2rem 0",
          fontWeight: "700",
          fontSize: "1.2rem",
        }}
        className={styles.destination_modal_title}
      >
        <b>{content.title}</b>
      </div>
      {content.text.split("<br>").map((item) => (
        <p
          style={{ marginTop: 20 }}
          dangerouslySetInnerHTML={{ __html: item }}
        ></p>
      ))}
      <div className={styles.answers}>
        <div onClick={onChange}>I'm Eligible</div>
        <div
          onClick={onNotEligible}
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          I'm Not Eligible
        </div>
      </div>
    </div>
  );
};

export default TugoEligibility;
