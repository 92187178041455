import ACTIONS from "../../constants/constants";

const initState = null;

export default (state = initState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_MODAL_CONTENT:
      return { type: action.active, props: action.props };
    case ACTIONS.CLEAR_MODAL_CONTENT:
      return null;
    default:
      return state;
  }
};

export const medicalPopupCompleted = (state = false, action) => {
  if (action.type === ACTIONS.CHECK_MEDICAL) return true;

  return state;
};
export const tugoEligible = (state = false, action) => {
  if (action.type === ACTIONS.TUGO_ELIGIBLE) return true;
  if (action.type === ACTIONS.TUGO_NOT_ELIGIBLE) return false;
  return state;
};
