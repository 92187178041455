import ACTIONS from "../../constants/constants";
import { isDev } from "../../lib/dev";
const devState = '{"from":{"name":"Angola","flag":"https://restcountries.eu/data/ago.svg","latlng":[-12.5,18.5]},"to":"Nova Scotia","DEPARTING_DATE_month":"09","DEPARTING_DATE_day":"10","DEPARTING_DATE_year":"2020","RETURNING_DATE_month":"06","RETURNING_DATE_day":"06","RETURNING_DATE_year":"2021"}'


// '{"from":{"name":"Angola","flag":"https://restcountries.eu/data/ago.svg","latlng":[-12.5,18.5]},"to":"Newfoundland and Labrador ","DEPARTING_DATE_month":"03","DEPARTING_DATE_day":"06","DEPARTING_DATE_year":"2021","RETURNING_DATE_month":"03","RETURNING_DATE_day":"11","RETURNING_DATE_year":"2021"}'


const initialState = {
  from: {},
  // to: [],
  to: "",

  DEPARTING_DATE_month: "",
  DEPARTING_DATE_day: "",
  DEPARTING_DATE_year: "",

  RETURNING_DATE_month: "",
  RETURNING_DATE_day: "",
  RETURNING_DATE_year: "",
  ...isDev ? JSON.parse(devState) : {}
};
let now = new Date();

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_COUNTRY_FROM:
      return {
        ...initialState,
        from: action.from,
        // to: []
        to: ""
      };
    case ACTIONS.SELECT_PROVINCES_TO:
      return {
        ...state,
        to: action.to,

        DEPARTING_DATE_month: "",
        DEPARTING_DATE_day: "",
        DEPARTING_DATE_year: "",

        RETURNING_DATE_month: "",
        RETURNING_DATE_day: "",
        RETURNING_DATE_year: ""
      };

    case "clear":

      return initialState;

    case ACTIONS.SELECT_DEPARTING_DATE:
        return {
          ...state,
          DEPARTING_DATE_month: action.DEPARTING_DATE_month,
          DEPARTING_DATE_day: action.DEPARTING_DATE_day,
          DEPARTING_DATE_year: action.DEPARTING_DATE_year
        };
    case ACTIONS.SELECT_RETURNING_DATE:
        return {
          ...state,
          RETURNING_DATE_month: action.RETURNING_DATE_month,
          RETURNING_DATE_day: action.RETURNING_DATE_day,
          RETURNING_DATE_year: action.RETURNING_DATE_year
        };
      
    case 'SET_DATE_FIELDS': 
        return {
          ...state,
          ...action.fields.reduce((acc, item) => ({...acc, [item.field]: item.value}), {})
        }

    case ACTIONS.CLEAR_TRAVEL_PLAN:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
