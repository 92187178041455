import ACTIONS from "../../constants/constants";

const initialState = {
  purpose: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CHOOSE_PURPOSE:
      return {
        purpose: action.purpose
      };
    case ACTIONS.CLEAR_PURPOSE:
      return {
        purpose: ""
      };
    default:
      return state;
  }
};
