import React, { useCallback, memo } from "react";
import { connect } from "react-redux";
import styles from "./modal.module.scss";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { Transition } from "react-transition-group";
import { lazyImport, Async } from "../../Async";

import {
  addModalTemplate,
  clearModalTemplate,
  setTugoNotEligible,
} from "../../redux/actions/modalActions";
import MedicalQuestions from "../FPFSStepFive/Modals/MedicalQuestions";
import VideoModal from "../FPFSStepFirst/VideoModal/VideoModal";
import DestinationNonMedicalModal from "../FPFSStepFive/Policy/DestinationNonMedicalModal";

import GMSbenefitsModal from "../../components/FPFSStepFive/Policy/GMSbenefitsModal";
import TugoDeclaration from "../FPFSStepFive/Policy/TugoDeclaration";
import TugoEligibility from "../FPFSStepFive/Policy/TugoEligibility";

const TugoMedicalCoverageModal = Async(
  lazyImport("./components/FPFSStepFive/Policy/TugoMedicalCoverageModal")
);
const TugoNonMedicalModal = Async(
  lazyImport("./components/FPFSStepFive/Policy/TugoNonMedicalModal")
);
// const GmsSubmitModal = Async(
//   lazyImport("./components/FPFSStepFive/Policy/GmsSubmitModal")
// );

const DestinationOptionalMedical = Async(
  lazyImport("./components/FPFSStepFive/Policy/DestinationOptionalMedical")
);

const TravelanceOptionalMedical = Async(
  lazyImport("./components/FPFSStepFive/Policy/TravelanceOptionalMedical")
);

const GMSSubmit = Async(
  lazyImport("./components/FPFSStepFive/Modals/GMSSubmit")
);

const GMSKickout = Async(
  lazyImport("./components/FPFSStepFive/Modals/GMSKickout")
);

const TravelanceKickout = Async(
  lazyImport("./components/FPFSStepFive/Modals/TravelenceKickout")
);

const Modal = ({
  addModalTemplate,
  clearModalTemplate,
  modalTemp,
  traveler,
  ...props
}) => {
  const handleClose = useCallback(
    (e) => {
      clearModalTemplate();
    },
    [clearModalTemplate]
  );
  // console.log(traveler)
  const mProps = (modalTemp && modalTemp.props) || {};

  const renderModalContent = useCallback(() => {
    if (!modalTemp || !handleClose) return null;

    mProps.handleClose = handleClose;

    switch (modalTemp.type) {
      case "BENEFITS_MEDICAL_COVERAGE": {
        return <GMSbenefitsModal {...mProps} />;
      }
      case "TUGO_MEDICAL_COVERAGE": {
        return <TugoMedicalCoverageModal {...mProps} />;
      }
      case "TUGO_NONMEDICAL_COVERAGE": {
        return <TugoNonMedicalModal {...mProps} />;
      }
      case "TUGO_DECLARATION": {
        return <TugoDeclaration {...mProps} />;
      }
      case "TUGO_ELIGIBILITY": {
        return <TugoEligibility {...mProps} />;
      }
      case "GMS_SUBMIT": {
        return <GMSSubmit {...mProps} />;
      }
      case "DESTINATION_OPTIONAL_MEDICAL":
        return <DestinationOptionalMedical {...mProps} />;

      case "TRAVELANCE_OPTIONAL_MEDICAL":
        return <TravelanceOptionalMedical {...mProps} />;
      case "KICKOUT_GMS":
        return <GMSKickout {...mProps} />;
      case "KICKOUT_TRAVELANCE":
        return <TravelanceKickout {...mProps} />;
      case "UNDER_55":
        // console.log({...mProps})
        return <MedicalQuestions travelers={traveler} {...mProps} />;

      case "VIDEO":
        return <VideoModal {...mProps} />;

      case "DESTINATION_NONMEDICAL_COVERAGE":
        return <DestinationNonMedicalModal {...mProps} />;

      case "TRAVELANCE_NONMEDICAL_COVERAGE":
        return <DestinationNonMedicalModal {...mProps} />;

      default:
        return null;
    }
  }, [modalTemp, mProps, traveler, handleClose]);

  const stopPrpg = (e) => {
    e.stopPropagation();
  };

  const transitionStyles = {
    entering: { opacity: 0, transform: "scale(.3)", borderRadius: "50%" },
    entered: { opacity: 1, transform: "scale(1)", borderRadius: "0" },
    exiting: { opacity: 0, transform: "scale(.3)", borderRadius: "50%" },
    exited: { opacity: 0 },
  };

  const handleTugoClose = () => {
    handleClose();
    setTugoNotEligible();
  };

  return (
    <Transition
      in={modalTemp ? true : false}
      timeout={300}
      unmountOnExit={true}
    >
      {(state) => (
        <div
          className={`${styles.fluid} dropdownSelect`}
          onClick={
            modalTemp && modalTemp.type === "TUGO_ELIGIBILITY"
              ? handleTugoClose
              : handleClose
          }
          style={{
            ...transitionStyles[state],
          }}
        >
          <div
            style={mProps.style || {}}
            className={`${styles.container} ${
              mProps.noPadding ? styles.no_padding : ""
            }`}
            onClick={stopPrpg}
          >
            {mProps.noPadding ? (
              <div
                className={styles.close_video}
                onClick={
                  modalTemp && modalTemp.type === "TUGO_ELIGIBILITY"
                    ? handleTugoClose
                    : handleClose
                }
              ></div>
            ) : (
              <button
                className={styles.close}
                onClick={
                  modalTemp && modalTemp.type === "TUGO_ELIGIBILITY"
                    ? handleTugoClose
                    : handleClose
                }
              >
                &#10006;
              </button>
            )}
            <div className={styles.content}>
              {mProps.noPadding ? (
                renderModalContent()
              ) : (
                <PerfectScrollbar>
                  <div>{renderModalContent()}</div>
                </PerfectScrollbar>
              )}
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

const mapStateToProps = ({ modalTemp, traveler, tugoEligible }) => ({
  modalTemp,
  tugoEligible,
  travelers: traveler.travelers,
});
const mapDispatchToProps = {
  addModalTemplate,
  clearModalTemplate,
  setTugoNotEligible,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Modal));
