import React, { useMemo } from "react";

import styles from "./checkbox.module.scss";

const CheckBox = ({
  name = "",
  min = false,
  data,
  activity = false,
  onChange = () => {}
}) => {
  const renderState = useMemo(
    () =>
      `${styles.container} ${activity && styles.active} ${min &&
        styles.container_min}`,
    [min, activity]
  );

  return (
    <div
      className={renderState}
      data-value={data.value}
      data-name={name}
      onClick={onChange}
    >
      <div className={styles.radio}></div>
      <div className={styles.title}>{data.title}</div>
    </div>
  );
};

export default CheckBox;
