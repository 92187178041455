import React, { Fragment } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import styles from "./policy.module.scss";

const GMSbenefitsModal = ({
  gmsPolicy,
  tugoPolicy,
  destinationPolicy,
  travelancePolicy,
  intl,
  benefitsModal
}) => {
  // console.log(gmsPolicy, tugoPolicy, destinationPolicy);

  return (
    <Fragment>
      {gmsPolicy.selected && (
        <div className={styles.modal_container}>
          <div className={styles.modal_logo_container}>
            <img
              src={gmsPolicy.img}
              alt="logo"
              className={`${styles.policy_logo} ${styles.policy_logo_mod}`}
            />
          </div>
          <div className={styles.modal_content}>
            <div className={styles.modal_content_title}>
              <div className={styles.modal_content_benefit}>
                {intl.formatMessage({ id: "step.five.benefits" })}
              </div>
              <div className={styles.modal_content_details}>
                {intl.formatMessage({ id: "step.five.details" })}
              </div>
            </div>

            {gmsPolicy.benefitsModal.map(item => (
              <div className={styles.modal_content_item} key={item.benefit}>
                <div className={styles.modal_content_benefit}>
                  {item.benefit}
                </div>
                {Array.isArray(item.details) ? (
                  <ul className={styles.modal_content_list}>
                    {item.details.map(i => (
                      <li key={i}>• {i}</li>
                    ))}
                  </ul>
                ) : (
                  <div className={styles.modal_content_details}>
                    {item.details}
                  </div>
                )}
              </div>
            ))}
            <div className={styles.link_to_more}>
              {intl.formatMessage({ id: 'policy.wording.text'})} <a target='__blank' href='./coverages/gms.pdf'> {intl.formatMessage({ id: 'policy.wording.link'})} </a>
            </div>
          </div>
        </div>
      )}
      {destinationPolicy.selected && (
        <div className={styles.modal_container}>
          <div className={styles.modal_logo_container}>
            <img
              src={destinationPolicy.img}
              alt="logo"
              className={`${styles.policy_logo} ${styles.policy_logo_mod}`}
            />
          </div>
          <div className={styles.modal_content}>
            <div className={styles.modal_content_title}>
              <div className={styles.modal_content_benefit}>
                {intl.formatMessage({ id: "step.five.benefits" })}
              </div>
              <div className={styles.modal_content_details}>
                {intl.formatMessage({ id: "step.five.details" })}
              </div>
            </div>

            {destinationPolicy.benefitsModal.map(item => (
              <div className={styles.modal_content_item} key={item.benefit}>
                <div className={styles.modal_content_benefit}>
                  {item.benefit}
                </div>
                {Array.isArray(item.details) ? (
                  <ul className={styles.modal_content_list}>
                    {item.details.map(i => (
                      <li key={i}>• {i}</li>
                    ))}
                  </ul>
                ) : (
                  <div className={styles.modal_content_details} dangerouslySetInnerHTML={{__html: item.details}}>
                  </div>
                )}
              </div>
            ))}
            <div className={styles.link_to_more}>
              {intl.formatMessage({ id: 'policy.wording.text'})} <a target='__blank' href='./coverages/destination.pdf'> {intl.formatMessage({ id: 'policy.wording.link'})} </a>
            </div>
          </div>
        </div>
      )}
      {travelancePolicy.selected && (
        <div className={styles.modal_container}>
          <div className={styles.modal_logo_container}>
            <img
              src={travelancePolicy.img}
              alt="logo"
              className={`${styles.policy_logo} ${styles.policy_logo_mod}`}
            />
          </div>
          <div className={styles.modal_content}>
            <div className={styles.modal_content_title}>
              <div className={styles.modal_content_benefit}>
                {intl.formatMessage({ id: "step.five.benefits" })}
              </div>
              <div className={styles.modal_content_details}>
                {intl.formatMessage({ id: "step.five.details" })}
              </div>
            </div>

            {travelancePolicy.benefitsModal.map(item => (
              <div className={styles.modal_content_item} key={item.benefit}>
                <div className={styles.modal_content_benefit}>
                  {item.benefit}
                </div>
                {Array.isArray(item.details) ? (
                  <ul className={styles.modal_content_list}>
                    {item.details.map(i => (
                      <li key={i}>• {i}</li>
                    ))}
                  </ul>
                ) : (
                  <div className={styles.modal_content_details} dangerouslySetInnerHTML={{__html: item.details}}>
                  </div>
                )}
              </div>
            ))}
            <div className={styles.link_to_more}>
            *Coverage under this plan is only for emergencies and does not include routine check-ups. <br/>
            For the definition of ‘emergency’, please review the ‘definitions’ section of the policy wording.
            </div>
          </div>
        </div>
      )}
      {benefitsModal && (
        <div className={styles.modal_container}>
          <div className={styles.modal_logo_container}>
            <img
              src={tugoPolicy.img}
              alt="logo"
              className={`${styles.policy_logo} ${styles.policy_logo_mod}`}
            />
          </div>
          <div className={styles.modal_content}>
            <div className={styles.modal_content_title}>
              <div className={styles.modal_content_benefit}>
                {intl.formatMessage({ id: "step.five.benefits" })}
              </div>
              <div className={styles.modal_content_details}>
                {intl.formatMessage({ id: "step.five.details" })}
              </div>
            </div>

            {benefitsModal.map(item => (
              <div className={styles.modal_content_item} key={item.benefit}>
                <div className={styles.modal_content_benefit}>
                  {item.benefit}
                </div>
                {Array.isArray(item.details) ? (
                  <ul className={styles.modal_content_list}>
                    {item.details.map(i => (
                      <li key={i}> {i.title ? i.title : `• ${i}`}</li>
                    ))}
                  </ul>
                ) : (
                  <div className={styles.modal_content_details} dangerouslySetInnerHTML={{__html: item.details}}>
                  </div>
                )}
              </div>
            ))}
            <div className={styles.link_to_more}>
              {intl.formatMessage({ id: 'policy.wording.text'})} <a target='__blank' href='./coverages/tugo.pdf'> {intl.formatMessage({ id: 'policy.wording.link'})} </a>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = ({ gmsPolicy, tugoPolicy, destinationPolicy,travelancePolicy }) => ({
  gmsPolicy,
  tugoPolicy,
  destinationPolicy,
  travelancePolicy
});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GMSbenefitsModal));
