import ACTION from "../../constants/constants";

export const gmsChange = obj => ({
  type: ACTION.GMS_CHANGE,
  obj
});
export const tugoChange = obj => ({
  type: ACTION.TUGO_CHANGE,
  obj
});
export const destinationChange = obj => ({
  type: ACTION.DESTINATION_CHANGE,
  obj
});

export const selectInsurer = obj => ({
  type: ACTION.SELECT_INSURER,
  obj
});

export const travelanceChange = obj => ({
  type: ACTION.TRAVELANCE_CHANGE,
  obj
});
// =====================
export const gmsClearPolicy = () => ({
  type: ACTION.POLICY_CLEAR_GMS
});
export const tugoClearPolicy = () => ({
  type: ACTION.POLICY_CLEAR_TUGO
});
export const destinationClearPolicy = () => ({
  type: ACTION.POLICY_CLEAR_DESTINATION
});

export const travelanceClearPolicy = () => ({
  type: ACTION.POLICY_CLEAR_TRAVELANCE
});

export const clearInsurer = obj => ({
  type: ACTION.CLEAR_INSURER,
  obj
});

export const removeGMS = payload => ({
  type: ACTION.GMS_REMOVE,
  payload
});

export const removeTravelance = payload => ({
  type: ACTION.TRAVELANCE_REMOVE,
  payload
})
