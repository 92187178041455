import React, { useState, useCallback, memo } from "react";
import styles from "./mq.module.scss";
import stylesss from "../../FPFSStepFour/fpfsstepfour.module.scss";
import { calculateAge } from "../../../lib/dev";

import {
  gmsClearPolicy,
  destinationClearPolicy,
  travelanceClearPolicy,
  tugoClearPolicy,
  clearInsurer,
  selectInsurer,
  gmsChange,
  removeGMS,
} from "../../../redux/actions/policyActions";
import { changeStep, blockNextStep } from "../../../redux/actions/step";
import { useDispatch, connect } from "react-redux";
import { clearPurpose, clearTraveler } from "../../../redux/actions/travelers";
import { clearTravelPlan } from "../../../redux/actions/travelPlan";
import constants from "../../../constants/constants";
import CheckBox from "../../CheckBox/CheckBox";
import TextInput from "../../TextInput/TextInput";
import MySelect from "../../MySelect/MySelect";
const opened = {
  mm: false,
  dd: false,
  yy: false,
};
const questions = [
  `1. are eighty (80) years of age or older on the policy effective date; `,
  `2. have any reason to seek medical treatment, excluding the regular care of a chronic condition or medical evaluation required to satisfy travel visa requirements;`,
  `3. are currently in Canada, and have ever been denied similar<br/>coverage offered by another Canadian insurer;  `,
  `4. are currently in Canada, and had more than $5,000 in medical<br/>treatment in the last twelve (12) months while in Canada;`,
  `5. are expecting medical treatment for heart disease;`,

  //TODO 6.

  `6. are waiting for test(s) for a suspected heart condition;`,
  `7. are taking prescription drugs for heart disease while taking<br/>insulin to treat diabetes;`,
  `8. have an implantable cardioverter defibrillator (ICD); <br/>   daily living (ADL) if you are seventy (70) years of age or older;<br/>   and/or`,
  "9. fainted or fell more than once without medical diagnosis (syncope);   ",
  "10. use home oxygen for a medical condition;  ",
  "11. take oral steroids to treat a lung condition;",
  "12. are being treated for cancer or have Metastic Cancer;",
  "13. have a vascular aneurysm that is surgically untreated;",
  "14.	have ever had: <br/>a.	a valve replacement;<br/>   b.	kidney (renal) dialysis; or <br/>   c.	an organ transplant; ",
  "15. were diagnosed; received new medical treatment (e.g. <br/>   consultation, tests or prescription drugs); or had a change in <br/>   your medical treatment (e.g. a stop, start or dosage change to a prescription drug, other than a dosage change of Coumadin or 	Warfarin)<br/> for, any of the following conditions in the<br/>last twelve (12) months: <br/>a.	congestive heart failure; <br/>b.	atrial flutter; <br/>c.	atrial / ventricular fibrillation; <br/> d.	peripheral vascular disease;<br/>e.	terminal illness;<br/> f.	blood clots; or<br/> g.	gastrointestinal bleeding;",
  "16. require assistance from another person(s) with activities of <br/>  daily living (ADL) if you are seventy (70) years of age or older.  ",
];

const questions_under = [
  `1. have any reason to seek medical treatment, excluding the <br/>   regular care of a chronic condition or medical evaluation <br/>    required to satisfy travel visa requirements;<br/>`,
  `2. are currently in Canada, and have ever been denied similar<br/>     coverage offered by another Canadian insurer; and<br/>`,
  `3. are currently in Canada, and had more than $5,000 in<br/>    medical treatment in the last twelve (12) months while in<br/> Canada.  `,
];

const clearCbs = [
  () => changeStep(1),
  clearPurpose,
  clearTravelPlan,
  clearTraveler,
  gmsClearPolicy,
  tugoClearPolicy,
  destinationClearPolicy,
  travelanceClearPolicy,
  clearInsurer,
];
const initState = {
  fname: "",
  lname: "",
  gender: 1,
  dateMonth: "",
  dateDay: "",
  dateYear: "",
};
const MedicalQuestions = ({
  logo,
  step,
  handleClose,
  cb,
  ages,
  travelers,
  selectInsurer,
  gmsChange,
  gmsPolicy,
  changeStep,
}) => {
  console.log(ages);
  console.log(1231412312315412, travelers);
  const [yesSelected, setYesContent] = React.useState(false);
  const [declacationSelected, setDeclacationSelected] = React.useState(false);
  const [yesWaitPer, setYesWaitPer] = React.useState(false);
  const [yesClick, setYesClick] = React.useState();
  const [checkStatus, setCheckStatus] = React.useState(0);
  const [traveler, setTraveler] = useState(initState);
  const [blur, setBlur] = useState(false);
  const [days15Error, setDays15Error] = useState(false);
  const [years18Error, setYears18Error] = useState(false);
  const [addUserError, setAddUserError] = useState(false);
  const [errors, setErrors] = useState({});
  const [openedSelect, setOpenedSelect] = useState(opened);
  const [firstCheckbox, setFirstCheckbox] = React.useState(false);
  const [secondCheckbox, setSecondCheckbox] = React.useState(false);
  const [thirdCheckbox, setThirdCheckbox] = React.useState(false);
  const [travelersGMS, setTravelersGMS] = React.useState({});
  let now = new Date();

  React.useEffect(() => {}, []);
  const handleChange = useCallback(
    ({ target }) => {
      setErrors(() => {
        for (let i in errors) {
          if (i === target.name) {
            delete errors[i];
          }
        }
        return { ...errors };
      });

      setDays15Error(false);
      setYears18Error(false);
      console.log(traveler);
      setTraveler({
        ...traveler,
        [target.name]: target.value,
      });
    },
    [
      traveler,
      errors,
      gmsPolicy,
      gmsChange,
      firstCheckbox,
      secondCheckbox,
      thirdCheckbox,
    ]
  );

  const handleSelDate = useCallback(
    () => (e) => {
      setErrors(() => {
        for (let i in errors) {
          if (i === e.dataset.name) {
            delete errors[i];
          }
        }
        return { ...errors };
      });

      setDays15Error(false);
      setYears18Error(false);
      setTraveler({
        ...traveler,
        [e.dataset.name]: e.dataset.value,
      });
    },
    [errors, traveler]
  );
  const dispatch = useDispatch();
  function changeStatus(val) {
    setCheckStatus(val);
    switch (val) {
      case 1:
        setFirstCheckbox(true);
        setSecondCheckbox(false);
        setThirdCheckbox(false);
        break;
      case 2:
        setFirstCheckbox(false);
        setSecondCheckbox(true);
        setThirdCheckbox(false);
        break;
      case 3:
        setFirstCheckbox(false);
        setSecondCheckbox(false);
        setThirdCheckbox(true);
        break;
      default:
        break;
    }
  }
  React.useEffect(() => {
    if (yesSelected || declacationSelected || yesWaitPer) {
      [...document.querySelectorAll(".scrollbar-container")].forEach(
        (i) => (i.scrollTop = 0)
      );
    }
  }, [yesSelected, declacationSelected, yesWaitPer]);

  const togggleBlur = useCallback(() => {
    setBlur((prev) => !prev);
  }, []);

  // React.useEffect((e)=>{
  //
  // })

  const renderDeclaration = () => {
    const onAgree = () => {
      let type = "OutsideOfCanada";
      if (firstCheckbox === true) {
        type = "InCanadaReplacingACurrentPlan";
      } else if (secondCheckbox === true) {
        type = "InCanadaForLessThan30DaysAndNoCanadianPlan";
      } else if (thirdCheckbox === true) {
        type = "InCanadaForMoreThan30DaysAndNoCanadianPlan";
      } else {
        type = "OutsideOfCanada";
      }
      // if (cb) {
      cb();
      // }
      // selectInsurer(gmsPolicy);
      selectInsurer(gmsPolicy);
      gmsChange({
        ...gmsPolicy,
        policy_number: traveler.lname,
        expiry_date: `${traveler.dateYear}-${traveler.dateMonth}-${traveler.dateDay}`,
        visitor_type: type,
        company_name: traveler.fname,
      });
      changeStep(++step);
      dispatch({ type: constants.CHECK_MEDICAL });
      handleClose();
    };

    //TODO finish layout
    return (
      <div className={styles.declaration}>
        <div className={styles.declaration_content}>
          <b>DECLARATION</b>
          <br />
          <p>
            I/We ("I") declare the statements made herein are true and complete
            and shall form part of my application for coverage. I hereby
            authorize any physician, health care provider, other person,
            hospital, institution, or insurance company to release to Group
            Medical Services and/or its authorized agents, representatives,
            affiliates or other service providers (collectively "GMS") any
            information covering my medical history, symptoms, treatment,
            examination, diagnosis and/or services rendered to myself or any of
            my dependants herein listed.
          </p>
          <br />
          <p>
            For the purposes of administering any Group Medical Services ("GMS")
            benefits, products or services (collectively "benefits") and/or
            determining eligibility for benefits, I authorize GMS to: (a)
            collect, store and use any personal information which I have
            provided to GMS or personal information obtained pursuant to clause
            (b); and/or (b) obtain personal information about me (or any other
            person listed herein) from, or disclose such personal information
            to: my Government Health Insurance Plan; the operator of any
            hospital, clinic, or other health facility; a doctor or other health
            care provider; any insurance company; or any other service provider
            or third party as may be reasonably required for the purposes
            described above.
          </p>
          <br />
          <p>
            I warrant that neither I, nor any person herein listed, have any
            additional coverage through any insurer other than the information
            listed herein. Should I, or any person herein listed, subsequently
            obtain additional coverage through any insurer, while covered under
            this contract, I will immediately advise GMS in writing. I hereby
            authorize GMS to coordinate any eligible expenses with any
            additional insurer that I, or any person herein listed, may have
            coverage under.
          </p>
          <br />
          <p>
            I understand that, whether before or after my application, any
            misrepresentation, incorrect or concealed information or failure to
            fully complete all sections of the application may void my coverage.
            I declare that, if I am signing on behalf of any person(s), I have
            the authority to sign on behalf of such person(s) listed herein and
            confirm that each of the above declarations and authorizations are
            also provided on behalf of such person(s).
          </p>
        </div>
        <div
          className={styles.declaration_button}
          style={{ maxWidth: 150, marginTop: 30, margin: "0 auto" }}
        >
          <CheckBox
            name="iAgree"
            data={{
              title: `I Agree`,
              value: `iAgree`,
            }}
            min={true}
            activity={false}
            onChange={onAgree}
          />
        </div>
      </div>
    );
  };

  const renderYes = () => {
    const onClick = () => {
      handleClose();

      dispatch(removeGMS(true));
    };

    return (
      <div className={styles.yes_content}>
        <div className={styles.yes_title}>
          <h3> We're sorry, </h3>{" "}
          <span> insurer cannot insure you at this time.</span>
        </div>
        <div className={styles.return}>
          <div
            className={`${styles.answer} ${styles.answer_return}`}
            onClick={onClick}
          >
            {" "}
            RETURN TO QUOTE{" "}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const renderUnder = () => {
      return (
        <>
          <div className={styles.title}>
            <div
              className={styles.question}
              style={{ backgroundColor: "#363636" }}
            >
              {travelers.map((item) => (
                <div key={item} style={{ color: "white" }}>
                  {calculateAge(
                    new Date(+item.dateYear, +item.dateMonth, +item.dateDay)
                  ) < 55
                    ? `${item.fname} ${item.lname}`
                    : null}
                </div>
              ))}
            </div>

            <h3>
              {" "}
              If you are under fifty-five (55) years of age <br /> you are NOT
              eligible if you:{" "}
            </h3>
          </div>
          <div className={styles.list}>
            {questions_under.map((item) => (
              <div
                className={styles.question}
                key={item}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
          </div>
        </>
      );
    };

    const renderOver = () => {
      return (
        <>
          <div className={styles.title}>
            <div
              className={styles.question}
              style={{ backgroundColor: "#363636" }}
            >
              {travelers.map((item) => (
                <div key={item} style={{ color: "white" }}>
                  {calculateAge(
                    new Date(+item.dateYear, +item.dateMonth, +item.dateDay)
                  ) >= 55
                    ? `${item.fname} ${item.lname}`
                    : null}
                </div>
              ))}
            </div>
            <h3>
              {" "}
              If you are fifty-five (55) years of age and older <br /> you are
              NOT eligible if you:{" "}
            </h3>
          </div>
          <div className={styles.list}>
            {questions.map((item) => (
              <div
                className={styles.question}
                key={item}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
          </div>
        </>
      );
    };

    return (
      <>
        <div className={styles.wrap}>
          {ages.under ? renderUnder() : null}
          {ages.over ? renderOver() : null}
        </div>
        <div className={styles.answers}>
          <div
            className={`${styles.answer} ${styles.yes}`}
            onClick={() => setYesContent(true)}
          >
            {travelers.length > 1 ? (
              <>We are Not Eligibile</>
            ) : (
              <>I’m Not Eligibile</>
            )}
          </div>
          <div
            className={`${styles.answer} ${styles.no}`}
            onClick={() => setYesWaitPer(true)}
          >
            {travelers.length > 1 ? <>We are Eligibile</> : <>I’m Eligibile</>}
          </div>
        </div>
      </>
    );
  };

  const WaitPer = () => {
    const onYesClick = () => {
      const firstCheck = () => {
        return (
          <form
            style={{ padding: "20px 50px" }}
            className={stylesss.form && stylesss.form_active}
          >
            <div className={styles.waitper}>
              <div
                style={{ padding: "0", marginRight: "5px" }}
                className={stylesss.form__item}
              >
                <h3 className={stylesss.title}>
                  {/* {intl.formatMessage({ id: "step.four.travelersName_f" })} */}
                  INSURANCE COMPANY
                </h3>
                <TextInput
                  type="text"
                  name="fname"
                  value={traveler.fname}
                  // value={""}

                  placeholder={"Name"}
                  // intl.formatMessage({ id: "step.four.FName" })
                  onChange={handleChange}
                  error={errors.fname}
                />
              </div>
              <div
                style={{ padding: "0", marginLeft: "5px" }}
                className={stylesss.form__item}
              >
                <h3 className={stylesss.title}>
                  {/* {intl.formatMessage({ id: "step.four.travelersName_l" })} */}
                  POLICY NUMBER
                </h3>
                <TextInput
                  type="text"
                  name="lname"
                  value={traveler.lname}
                  // value={""}

                  placeholder={"Number"}
                  // intl.formatMessage({ id: "step.four.LName" })
                  onChange={handleChange}
                  onBlur={togggleBlur}
                  error={errors.lname}
                />
              </div>
            </div>
            <h3 className={stylesss.title}>
              {/* {intl.formatMessage({ id: "step.four.travelersBirthDate" })} */}{" "}
              POLICY EXPIRY DATE
            </h3>
            <div>
              <div className={stylesss.dateCont}>
                <div className={stylesss.dateCont__min}>
                  <MySelect
                    val={traveler.dateMonth}
                    name="dateMonth"
                    type="number"
                    min="1"
                    max="12"
                    placeholder="month"
                    // placeholder={intl.formatMessage({ id: "step.four.month" })}
                    handleChange={handleSelDate()}
                    disabled={false}
                    opened={openedSelect.mm}
                    error={errors.dateMonth}
                  />
                </div>

                <div className={stylesss.dateCont__min}>
                  <MySelect
                    val={traveler.dateDay}
                    // value={""}

                    name="dateDay"
                    type="number"
                    min="1"
                    max="31"
                    placeholder="date"
                    // placeholder={intl.formatMessage({ id: "step.four.day" })}
                    handleChange={handleSelDate()}
                    disabled={false}
                    opened={openedSelect.dd}
                    error={errors.dateDay}
                  />
                </div>

                <div className={stylesss.dateCont__max}>
                  <MySelect
                    val={traveler.dateYear}
                    // value={""}

                    name="dateYear"
                    type="number"
                    placeholder="Year"
                    min={now.getFullYear() - 100}
                    max={
                      now.getMonth() + 1 < parseInt(traveler.dateMonth)
                        ? now.getFullYear() - 1
                        : now.getFullYear()
                    }
                    // placeholder={intl.formatMessage({ id: "step.four.year" })}
                    handleChange={handleSelDate()}
                    disabled={false}
                    scrollTo="1550"
                    opened={openedSelect.yy}
                    error={errors.dateYear}
                  />
                </div>
              </div>
            </div>
          </form>
        );
      };
      const secondCheck = () => {
        return (
          <div style={{ padding: "20px 50px" }}>
            You will have to wait 2 days from your effective date before you can
            make a claim for an illness under your plan.
          </div>
        );
      };
      const thirdCheck = () => {
        return (
          <div style={{ padding: "20px 50px" }}>
            You will have to wait 7 days from your effective date before you can
            make a claim for an illness under your plan.
          </div>
        );
      };
      return (
        <div className={styles.waitperCheck}>
          <div className={styles.waitperElem}>
            <CheckBox
              name="first"
              data={{
                title: `I am purchasing this plan to replace my current Canadian health insurance plan.`,
              }}
              min={true}
              activity={firstCheckbox}
              onChange={() => changeStatus(1)}
            />
          </div>
          {checkStatus === 1 ? firstCheck() : null}
          <div className={styles.waitperElem}>
            <CheckBox
              name="second"
              data={{
                title: `I have been in Canada for LESS than 30 days and am not currently covered by a Canadian health insurance plan.`,
              }}
              min={true}
              activity={secondCheckbox}
              onChange={() => changeStatus(2)}
            />
          </div>
          {checkStatus === 2 ? secondCheck() : null}
          <div className={styles.waitperElem}>
            <CheckBox
              name="third"
              data={{
                title: `I have been in Canada for MORE than 30 days and am not currently covered by a Canadian health insurance plan.`,
              }}
              min={true}
              activity={thirdCheckbox}
              onChange={() => changeStatus(3)}
            />
          </div>
          {checkStatus === 3 ? thirdCheck() : null}
        </div>
      );
    };
    return (
      <div className={styles.wrap}>
        <div className={styles.title}>
          <h3> Are you currently in canada? </h3>
        </div>

        <div className={styles.answers} style={{ justifyContent: "center" }}>
          <div
            style={yesClick ? { opacity: 1 } : { opacity: 0.5 }}
            className={`${styles.answer} `}
            onClick={() => setYesClick(true)}
          >
            yes
          </div>
          <div
            style={{ opacity: 0.5 }}
            className={`${styles.answer} `}
            onClick={() => {
              setDeclacationSelected(true);
              setYesWaitPer(false);
            }}
          >
            no
          </div>
        </div>
        {yesClick ? onYesClick() : null}
        <div className={styles.answers} style={{ justifyContent: "center" }}>
          {checkStatus > 0 ? (
            <div
              className={`${styles.answer} ${styles.no}`}
              onClick={() => {
                setDeclacationSelected(true);
                setYesWaitPer(false);
              }}
            >
              Continue
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <img src={logo} alt={"company"} />
        </div>
        {yesSelected && renderYes()}
        {declacationSelected && renderDeclaration()}
        {!yesSelected && !declacationSelected && !yesWaitPer && renderContent()}
        {yesWaitPer && WaitPer()}
      </div>
    </div>
  );
};
const mapStateToProps = ({ gmsPolicy, step, traveler }) => ({
  travelers: traveler.travelers,
  gmsPolicy,
  step: step.currentStep,
});
const mapDispatchToProps = { gmsChange, changeStep, selectInsurer };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(MedicalQuestions));
