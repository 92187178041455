import React, { useState, useCallback, useEffect, memo, useRef } from "react";
import styles from "./myselect.module.scss";

const MySelect = ({
  min = 0,
  max = 0,
  val = "",
  placeholder = "",
  name = "",
  opened = false,
  disabled = false,
  handleChange,
  error = "",
  text = "",
  options = null,
  arrow = false,
  scrollTo = 0,
  left = false,
  small_size = false,
  dropDown = false,
  ...props
}) => {
  const [toggle, setToggle] = useState(false);
  const optionsContainer = useRef(null);

  useEffect(() => {
    if (opened) {
      setToggle(true);
    }
  }, [opened]);

  let arr = [];
  if (!options) {
    for (let i = min; i <= max; i++) {
      if (i <= 9) {
        i = "0" + i;
      }
      arr.push(i);
    }
  }

  const handleSel = ({ target }) => {
    handleChange(target);
    setToggle(false);
  };

  const toggleState = useCallback(e => {
    e.stopPropagation();
    setToggle(prev => !prev);
  }, []);

  const openOption = useCallback(() => {
    !toggle && setToggle(true);
  }, [toggle]);

  useEffect(() => {
    if (toggle && optionsContainer && optionsContainer.current)
      optionsContainer.current.scrollTop = parseInt(scrollTo);
  }, [scrollTo, toggle]);

  useEffect(() => {
    const f = e => {
      e.target.dataset.name !== name && setToggle(false);
    };
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, [setToggle, name]);

  const getRootClass = useCallback(() => {
    if (disabled) {
      return `${styles.select} ${styles.select_disabled}`;
    } else if (error) {
      return `${styles.select} ${styles.select_error}`;
    } else if (val) {
      return `${styles.select} ${styles.active}`;
    }
    return styles.select;
  }, [disabled, error, val]);

  return (
    <div
      className={`${getRootClass()} ${small_size && styles.select_min}`}
      data-value={val}
      data-name={name}
      onClick={openOption}
      style={{ textAlign: left ? "left" : "center",padding:left ? "0 1.8vmin" : "0" }}
    >
      {arrow && (
        <div
          onClick={toggleState}
          className={
            toggle ? styles.arrow : `${styles.arrow} ${styles.arrow__activ}`
          }
        ></div>
      )}

      {val && !toggle ? (
        <div
          className={styles.select_val}
          style={{ textAlign: left ? "left" : "center" }}
        >
          {val}
        </div>
      ) : null}

      {!toggle && !val ? (
        <div
          className={styles.select_val}
          style={{ textAlign: left ? "left" : "center" }}
        >
          {placeholder}
        </div>
      ) : null}

      {toggle ? (
        <div className={`${dropDown ? styles.option__container_dropdown : styles.option__container} dropdownSelect`}>
          <div className={styles.option__container_scr} ref={optionsContainer}>
            {options
              ? options.map(item => (
                  <div
                    key={item}
                    onClick={handleSel}
                    data-name={name}
                    data-value={item}
                    id={item}
                    className={styles.option}
                    style={{ textAlign: left ? "left" : "center" }}
                  >
                    {`${item} ${text}`}
                  </div>
                ))
              : arr.map(a => (
                  <div
                    key={a}
                    onClick={handleSel}
                    data-name={name}
                    data-value={a}
                    id={a}
                    className={styles.option}
                    style={{ textAlign: left ? "left" : "center" }}
                  >
                    {a}
                  </div>
                ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(MySelect);
