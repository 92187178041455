import React from 'react'
import styles from "./policy.module.scss";
import CheckBox from '../../CheckBox/CheckBox';

const TugoDeclaration = ({content, onClick, handleClose}) => {
  const onChange = () => {
    handleClose()
    onClick()
  }
  
  return (
    <div style={{fontSize: '1em'}} className={styles.modal_container}>
      <div className={styles.modal_logo_container}>
        <img src={'./img/policy/tugo.png'} alt="logo" className={styles.policy_logo} />
      </div>
      <div style={{marginTop: 0}} className={styles.destination_modal_title}>
        <b>{content.title}</b>
      </div>
      {
        content.text.split('<br>').map((item) => (
          <p style={{marginTop: 20}} dangerouslySetInnerHTML={{__html: item}}></p>
        ))
      }
      <div
          className={styles.declaration_button}
          style={{ maxWidth: 150, marginTop: 30, margin: "0 auto" }}
        >
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2em'}}>
          <CheckBox
            name="iAgree"
            data={{
              title: `Yes to All`,
              value: `iAgree`,
            }}
            min={true}
            activity={false}
            onChange={onChange}
          />
        </div>
        </div>
    </div>
  )
}

export default TugoDeclaration