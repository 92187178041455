import React from 'react'
import styles from "./policy.module.scss";

const DestinationNonMedicalModal = ({nonMedBenefits, img}) => {
  return (
    <div className={styles.modal_container}>
      <div className={styles.modal_logo_container}>
        <img src={img} alt="logo" className={styles.policy_logo} />
      </div>
      <div className={styles.destination_modal_title}>
        <b>{nonMedBenefits.title}</b>
      </div>
      {
        nonMedBenefits.items.map((item) => {
          return <div style={{marginLeft: "1.5em", marginTop: '.7em'}} key={item}> - {item}</div>
        })
      }
    </div>
  )
}

export default DestinationNonMedicalModal
