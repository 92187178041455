import ACTIONS from "../../constants/constants";

const initStateTugo = {
  selected: false,
  price: "0",
  name: "TUGO",
  img: "/img/policy/tugo.png",
  coverage: "",
  deductible: "",

  pre_existing_medical_coverage: [],
  non_medical_coverage: [],
  sports_coverage: [],

  medical_coverage: [],
  nonmedical_coverage: []
};
const initStateGms = {
  selected: false,
  price: "0",
  name: "GMS",
  img: "/img/policy/gms.png",
  coverage: "",
  deductible: "",
  medical_coverage: [],
  nonmedical_coverage: [],
  pre_existing_medical_coverage: [],
  emer: [],
  policy_number:"0123",
  expiry_date:"12-05-2020",
  visitor_type:"OutsideOfCanada",
  company_name:"asdasd",
  pre: []
};
const initStateDestination = {
  selected: false,
  price: "0",
  name: "DESTINATION",
  img: "/img/policy/destination.png",
  coverage: "$25000",
  deductible: "$500",
  medical_coverage: [],
  nonmedical_coverage: [],
  pre_existing_medical_coverage: []
};

const initStateTravelance = {
  selected: false,
  price: "0",
  name: "TRAVELANCE",
  img: "/img/policy/travelance.png",
  coverage: "",
  deductible: "",
  medical_coverage: [],
  nonmedical_coverage: [],
  pre_existing_medical_coverage: []
};

const initStateInsurer = {};

// export const policyObj = (state = initState, action) => {
//   switch (action.type) {
//     case ACTIONS.POLICY_SELECT: {
//       let obj = { ...state, ...action.obj };

//       let res = {};
//       let non_med = [];

//       for (let i in obj) {
//         if (i.substr(i.length - 10) === "_colection") {
//           non_med = [...non_med, obj[i]];
//         } else {
//           res[i] = obj[i];
//         }
//       }

//       return { ...state, ...res, nonmedical_coverage: non_med };
//     }
//     case ACTIONS.POLICY_CLEAR: {
//       return { ...initState };
//     }
//     default: {
//       return state;
//     }
//   }
// };

export const tugoPolicy = (state = initStateTugo, action) => {
  switch (action.type) {
    case ACTIONS.TUGO_CHANGE: {
      return { ...state, ...action.obj };
    }
    case ACTIONS.POLICY_CLEAR_TUGO: {
      return { ...initStateTugo };
    }
    default: {
      return state;
    }
  }
};

export const gmsPolicy = (state = initStateGms, action) => {
  switch (action.type) {
    case ACTIONS.GMS_CHANGE: {
      return { ...state, ...action.obj };
    }
    case ACTIONS.POLICY_CLEAR_TUGO: {
      return { ...initStateGms };
    }
    default: {
      return state;
    }
  }
};

export const destinationPolicy = (state = initStateDestination, action) => {
  switch (action.type) {
    case ACTIONS.DESTINATION_CHANGE: {
      return { ...state, ...action.obj };
    }
    case ACTIONS.POLICY_CLEAR_DESTINATION: {
      return { ...initStateDestination };
    }
    default: {
      return state;
    }
  }
};

export const travelancePolicy = (state = initStateTravelance, action) => {
  switch (action.type) {
    case ACTIONS.TRAVELANCE_CHANGE: {
      return { ...state, ...action.obj };
    }
    case ACTIONS.POLICY_CLEAR_TRAVELANCE: {
      return { ...initStateTravelance };
    }
    default: {
      return state;
    }
  }
};

export const policyObj = (state = initStateInsurer, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_INSURER: {
      return { ...state, ...action.obj };
    }
    case ACTIONS.CLEAR_INSURER: {
      return { ...initStateInsurer };
    }
    default: {
      return state;
    }
  }
};

export const GMS_remove = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.GMS_REMOVE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const Travelance_remove = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.TRAVELANCE_REMOVE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
