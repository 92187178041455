import React, { memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { NavLink, Link } from "react-router-dom";

import { toggleNav } from "../../redux/actions/nav";
import { changeStep } from "../../redux/actions/step";
import { clearTraveler, clearPurpose } from "../../redux/actions/travelers";
import { clearTravelPlan } from "../../redux/actions/travelPlan";

import Burger from "../Burger/Burger";

import styles from "./navigation.module.scss";
import { PATH } from "../../constants/utils";

const Navigation = ({
  intl,
  nav,
  toggleNav,
  clearTraveler,
  clearPurpose,
  clearTravelPlan,
  changeStep,
  ...props
}) => {
  const closeNav = ({ target }) => {
    toggleNav(false);
    if (target.id !== "home-link") clearSteps();
  };

  const clearSteps = () => {
    clearPurpose();
    clearTravelPlan();
    clearTraveler();
    changeStep(1);
  };

  const navlinks = [
    { text: intl.formatMessage({ id: "nav.home" }), to: PATH, id: "home-link" },
    {
      text: intl.formatMessage({ id: "nav.policy" }),
      to: `${PATH}policy-view`,
    },
    { text: intl.formatMessage({ id: "nav.contacts" }), to: `${PATH}contacts` },
    { text: intl.formatMessage({ id: "nav.claim" }), to: `${PATH}makeaclaim` },
    { text: intl.formatMessage({ id: "nav.insurer" }), to: `${PATH}insurer` },
    { text: intl.formatMessage({ id: "nav.about" }), to: `${PATH}about` },
  ];

  return (
    <div
      className={
        nav ? `${styles.container} ${styles.active}` : styles.container
      }
    >
      <div className={styles.burger_cont}>
        <Burger />
      </div>

      <div className={styles.nav}>
        {navlinks.map((item, index) => (
          <NavLink
            key={item.text}
            exact={index === 0}
            to={item.to}
            className="nav-link"
            activeClassName="nav-link-active"
            onClick={closeNav}
            id={item.id}
          >
            {item.text}
          </NavLink>
        ))}
      </div>

      <div className={styles.social}>
        <a className={styles.social_iten} href="https://#">
          <img src="/img/zhihu.png" alt="Zhihu" />
        </a>
        <a className={styles.social_iten} href="https://#">
          <img src="/img/we-chat.png" alt="WeChat" />
        </a>
        <a
          className={styles.social_iten}
          href="https://www.linkedin.com/company/iware-travel/"
        >
          <img src="/img/linked-in.png" alt="Linkedin" />
        </a>
      </div>

      <div className={styles.license}>
        <div className={styles.license_title}>
          {intl.formatMessage({ id: "nav.license" })}
        </div>
        <img
          src="/img/broker-license-provider.png"
          alt="broker-license-provider"
        />
      </div>

      <div className={styles.viewer_privacy}>
        <Link to={`${PATH}privacy`}>
          {intl.formatMessage({ id: "view.privacy" })}
        </Link>
      </div>

      <div className={styles.copy}>
        © 2020 <span>iWare</span>Travel.ca
      </div>
    </div>
  );
};

const mapStateToProps = ({ nav }) => ({
  nav,
});

const mapDispatchToProps = {
  toggleNav,
  clearTraveler,
  clearPurpose,
  clearTravelPlan,
  changeStep,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(memo(Navigation)));
