import React, { memo, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./textinput.module.scss";

const TextInput = ({
  type,
  value,
  placeholder,
  onChange,
  onBlur,
  error,
  name,
  inputmode,
  autofocus = false,
  changePaste = () => {},
  _ref,
  ...props
}) => {
  const inpt = useRef(null);

  useEffect(() => {
    if(_ref) _ref.current = inpt.current
  }, [inpt, _ref])

  useEffect(() => {
    inpt && autofocus && inpt.current.focus();
  }, [autofocus]);

  return (
    <input
      name={name}
      inputMode={inputmode}
      className={
        !error
          ? `${styles.input} ${props.addClass}`
          : `${styles.input} ${styles.error} ${props.addClass}`
      }
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autofocus}
      onPaste={changePaste}
      ref={inpt}
    />
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  placholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

TextInput.defaultProps = {
  type: "text",
  value: "",
  placholder: "",
  inputmode: ""
};

export default memo(TextInput);
