import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import Burger from "../Burger/Burger";
import ToggleLanguage from "../ToggleLanguage/ToggleLanguage";

import styles from "./header.module.scss";
import { PATH } from "../../constants/utils";

const Header = ({ fp, step, ...props }) => {
  const [mounted, setMounted] = useState(false);

  const up = () => {
    if (fp) fp.moveTo("firstPage", 0);
  };

  useEffect(() => {
    setMounted(false);
    setTimeout(() => setMounted(true), 500);
  }, [step, fp]);

  return (
    <div className={`${styles.header} ${!mounted ? styles.header_hidden : ""}`}>
      <div className={styles.logo_wrap}>
        <Link to={PATH} onClick={up}>
          <Logo />
        </Link>
      </div>

      <div className={styles.wrap1}></div>
      <div className={styles.leng_wrap}>
        <ToggleLanguage />
      </div>

      <div className={styles.wrap2}></div>
      <div className={styles.burger_wrap}>
        <Burger />
      </div>
    </div>
  );
};
const mapStateToProps = ({ fp, step }) => ({
  fp,
  step: step.currentStep
});
export default connect(mapStateToProps)(Header);
