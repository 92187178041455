export default {
  CHANGE_STEP: "CHANGE_STEP",
  BLOCK_STEP: "BLOCK_STEP",
  BLOCK_STEP_TRIGGER: "BLOCK_STEP_TRIGGER",

  ADD_FP_API: "ADD_FP_API",

  TOGGLE_NAVIGATION: "TOGGLE_NAVIGATION",

  CHOOSE_PURPOSE: "CHOOSE_PURPOSE",
  CLEAR_PURPOSE: "CLEAR_PURPOSE",

  SELECT_COUNTRY_FROM: "SELECT_COUNTRY_FROM",
  SELECT_PROVINCES_TO: "SELECT_PROVINCES_TO",
  SELECT_DEPARTING_DATE: "SELECT_DEPARTING_DATE",
  SELECT_RETURNING_DATE: "SELECT_RETURNING_DATE",
  CLEAR_TRAVEL_PLAN: "CLEAR_TRAVEL_PLAN",
  BLOCK_TPFIELDS: "BLOCK_TPFIELDS",

  ADD_TREVELERS: "ADD_TREVELERS",
  REMOVE_TREVELERS: "REMOVE_TREVELERS",
  CLEAR_TREVELERS: "CLEAR_TREVELERS",

  ADD_FEARS: "ADD_FEARS",
  CLEAR_FEARS: "CLEAR_FEARS",

  BEGIN_API_CALL: "BEGIN_API_CALL",
  API_CALL_ERROR: "API_CALL_ERROR",
  API_CALL_END: "API_CALL_END",
  SEND_MAIL: "SEND_MAIL",

  ADD_SCENE_API: "ADD_SCENE_API",

  LOAD_COUNTRY_SUCCESS: "LOAD_COUNTRY_SUCCESS",
  LOAD_POLICY_SUCCESS: "LOAD_POLICY_SUCCESS",
  LOAD_MY_POLICY_SUCCESS: "LOAD_MY_POLICY_SUCCESS",

  ADD_MODAL_CONTENT: "ADD_MODAL_CONTENT",
  CLEAR_MODAL_CONTENT: "CLEAR_MODAL_CONTENT",

  GMS_CHANGE: "GMS_CHANGE",
  TUGO_CHANGE: "TUGO_CHANGE",
  DESTINATION_CHANGE: "DESTINATION_CHANGE",
  TRAVELANCE_CHANGE: "TRAVELANCE_CHANGE",
  SELECT_INSURER: "SELECT_INSURER",
  CLEAR_INSURER: "CLEAR_INSURER",

  POLICY_CLEAR_GMS: "POLICY_CLEAR_GMS",
  POLICY_CLEAR_TUGO: "POLICY_CLEAR_TUGO",
  POLICY_CLEAR_DESTINATION: "POLICY_CLEAR_DESTINATION",
  POLICY_CLEAR_TRAVELANCE: "POLICY_CLEAR_TRAVELANCE",

  GMS_OPEN: "GMS_OPEN",
  GMS_REMOVE: "GMS_REMOVE",

  TRAVELANCE_REMOVE: "TRAVELANCE_REMOVE",
  // =====================================================backendApi
  HELTH_API: "HELTH_API",
  SET_LEAD: "SET_LEAD",

  GET_INSURERS: "GET_INSURERS",

  CHECK_TRAVELERS_AGE: "CHECK_TRAVELERS_AGE",
  GET_CONDITIONS: "GET_CONDITIONS",

  SET_QUOTE_GMS: "SET_QUOTE_GMS",
  SET_QUOTE_DESTINATION: "SET_QUOTE_DESTINATION",
  SET_QUOTE_TRAVELANCE: "SET_QUOTE_TRAVELANCE",
  SET_QUOTE_TUGO: "SET_QUOTE_TUGO",

  PURCHASE_GMS: "PURCHASE_GMS",
  PURCHASE_DESTINATION: "PURCHASE_DESTINATION",
  PURCHASE_TRAVELANCE: "PURCHASE_TRAVELANCE",
  PURCHASE_TUGO: "PURCHASE_TUGO",

  GET_POLICY: "GET_POLICY",

  GET_TOKEN: "GET_TOKEN",

  CHECK_MEDICAL: "CHECK_MEDICAL",
  TUGO_ELIGIBLE: "TUGO_ELIGIBLE",
  TUGO_NOT_ELIGIBLE: "TUGO_NOT_ELIGIBLE",
};
