export const isDev = 
  false 
  || process.env.NODE_ENV === 'development'

export function calculateAge(birthday) { // birthday is a date
  var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = (today.getMonth() + 1) - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}