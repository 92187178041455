import ACTION from "../../constants/constants";

// function typeEndsOnSuccess(type) {
//   return type.substring(type.length - 8) === "_SUCCESS";
// }

const initState = { status: 0, message: null };

export default function(state = initState, action) {
  const { type, err } = action;
  // console.log(action, typeEndsOnSuccess(type));
  switch (type) {
    case ACTION.BEGIN_API_CALL:
      return { ...state, status: 1, message: null };

    case ACTION.API_CALL_ERROR:
      return { ...state, status: 0, message: err };
    case ACTION.API_CALL_END:
      return { ...state, status: 0, message: "end" };
    default:
      return state;
  }
}

//   if (type === ACTION.BEGIN_API_CALL) {
//     return { ...state, status: 1, message: null };
//   } else if (type === ACTION.API_CALL_ERROR || typeEndsOnSuccess(type)) {
//     return { ...state, status: 0, message: err };
//   }
//   return state;
// }
