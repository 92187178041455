import ACTION from "../../constants/constants";

export const resTravelesAge = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.CHECK_TRAVELERS_AGE:
      return payload;
    default:
      return state;
  }
};

export const resConditions = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.GET_CONDITIONS:
      return payload;
    default:
      return state;
  }
};

export const countries = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.LOAD_COUNTRY_SUCCESS:
      return payload;
    default:
      return state;
  }
};

// ================================================================START QUOTE
export const resQuoteGMS = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_QUOTE_GMS:
      return payload;
    default:
      return state;
  }
};

export const resQuoteDestination = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_QUOTE_DESTINATION:
      return payload;
    default:
      return state;
  }
};


export const resQuoteTravelance = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_QUOTE_TRAVELANCE:
      return payload;
    default:
      return state;
  }
};

export const resQuoteTugo = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_QUOTE_TUGO:
      return payload;
    default:
      return state;
  }
};
// ================================================================END QUOTE

// ================================================================START QUOTE
export const resPurchaseGMS = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.PURCHASE_GMS:
      return payload;
    default:
      return state;
  }
};

export const resPurchaseDestination = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.PURCHASE_DESTINATION:
      return payload;
    default:
      return state;
  }
};


export const resPurchaseTravelance = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.PURCHASE_TRAVELANCE:
      return payload;
    default:
      return state;
  }
};

export const resPurchaseTugo = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.PURCHASE_TUGO:
      return payload;
    default:
      return state;
  }
};
// ================================================================END QUOTE

// ================================================================START LEAD
export const resLead = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_LEAD:
      return payload;
    default:
      return state;
  }
};
// ================================================================END LEAD

// ================================================================START HEALTH
export const resHealth = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.HELTH_API:
      return payload;
    default:
      return state;
  }
};
// ================================================================END HEALTH

// ================================================================START INSURERS
export const resInsurers = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.GET_INSURERS:
      return payload;
    default:
      return state;
  }
};
// ================================================================END INSURERS

export const resPolicySearch = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.GET_POLICY:
      return payload;
    default:
      return state;
  }
};
