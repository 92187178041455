import ACTIONS from "../../constants/constants";

const initianState = false;
export const blockStep = (state = initianState, action) => {
  if (action.type === ACTIONS.BLOCK_STEP) {
    return action.active;
  }
  return state;
};

export const blockStepTrigger = (state = 0, action) => {
  if (action.type === ACTIONS.BLOCK_STEP_TRIGGER) {
    return Math.random() * 1000;
  } else if (action.type === ACTIONS.BLOCK_STEP) {
    return 0;
  }
  return state;
};
