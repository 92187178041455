import React, { Fragment, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";

import Header from "./components/Header/Header";
import Navigation from "./components/Navigation/Navigation";
import Modal from "./components/Modal/Modal";

import { getHealth, getToken } from "./redux/actions/apiAction";

import { lazyImport, Async } from "./Async";

import { PATH } from "./constants/utils";

const HomePage = Async(lazyImport("./pages/HomePage"));
const ContactUsPage = Async(lazyImport("./pages/ContactUsPage"));
const PageNotFound = Async(lazyImport("./pages/PageNotFound/PageNotFound"));
const Thanks = Async(lazyImport("./pages/Thanks"));
const PrivacyPolicy = Async(lazyImport("./pages/PrivacyPolicy/PrivacyPolicy"));
const ViewPolicyPage = Async(lazyImport("./pages/ViewPolicyPage"));
const PolicyDetailView = Async(
  lazyImport("./components/PolicyView/PolicyDetailView/PolicyDetailView")
);
const KickoutPage = Async(lazyImport("./pages/KickoutPage/KickoutPage"));
const Insurer = Async(lazyImport("./pages/Insurer/Insurer"));
const MakeAClaim = Async(lazyImport("./pages/MakeAClaim/MakeAClaim"));
const About = Async(lazyImport("./pages/About/About"));
const Admin = Async(lazyImport("./pages/Admin/Admin"));

function App() {
  const resHealth = useSelector((state) => state.resHealth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionStorage.getItem("iwareTraverl")) {
      sessionStorage.setItem("iwareTraverl", JSON.stringify({ id: uuid() }));
    }

    dispatch(getToken());
  }, [dispatch]);

  useEffect(() => {
    !resHealth && dispatch(getHealth());
  }, [dispatch, resHealth]);

  return (
    <Fragment>
      <Header />
      <Navigation />
      <Switch>
        <Route exact path={PATH} component={HomePage} />
        <Route path={`${PATH}contacts`} component={ContactUsPage} />
        <Route path={`${PATH}policy-view/:slug`} component={PolicyDetailView} />
        <Route path={`${PATH}policy-view`} component={ViewPolicyPage} />

        <Route path={`${PATH}kickout`} component={KickoutPage} />
        <Route path={`${PATH}insurer`} component={Insurer} />
        <Route path={`${PATH}makeaclaim`} component={MakeAClaim} />
        <Route path={`${PATH}about`} component={About} />
        <Route path={`${PATH}admin`} component={Admin} />

        <Route path={`${PATH}thanks`} component={Thanks} />
        <Route path={`${PATH}privacy`} component={PrivacyPolicy} />
        <Route component={PageNotFound} />
      </Switch>
      <Modal />
    </Fragment>
  );
}

export default App;
