import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import messages from "../../constants/messages";
export const LocaleContext = React.createContext();

export default ({ children }) => {
  const [locale, setLocale] = useState("en-CA");

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
