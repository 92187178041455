import ACTIONS from "../../constants/constants";
import { isDev } from "../../lib/dev";

const initialState = {
  currentStep: isDev ? 1 : 1
};

export default (state = initialState, action) => {
  if (action.type === ACTIONS.CHANGE_STEP) {
    return {
      currentStep: action.currentStep
    };
  }

  return state;
};
