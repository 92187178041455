import ACTIONS from "../../constants/constants";

const initialState = {
  fears: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_FEARS:
      return {
        fears: action.fears
      };
    case ACTIONS.CLEAR_FEARS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
