export default {
  "en-CA": {
    // ============================NAVIGATION
    "nav.home": "Get a quote",
    "nav.contacts": "Contact Us",
    "nav.license": "iWareTravel Insurance is licensed by:",
    "nav.policy": " View My Policy",
    "nav.claim": "Make A Claim",
    "nav.insurer": "Insurer",
    "nav.about": "About Us",

    // =========================stepNavigation
    "step.move.next": "CONTINUE",
    "step.move.prev": "PREVIOUS",

    // =========================contact us link
    "contUsLink.title": "NEED HELP?",
    "contUsLink.subTitle": "CONTACT US",

    // =========================firstStep
    "step.first.title": "Safe Travels,",
    "step.first.subTitle": "Begin with Travel Insurance.",
    "step.first.linkTitle": "GET A QUOTE IN 60 SECONDS",
    "step.first.linkSubTitle": "",

    "about.title_2": "Get to Know Us",

    "step.first.video": "Watch this Video to Learn More. ",
    "step.first.insuresLinkTitle": "Why Get Travel Insurance from Canada?",
    "step.first.insuresLinkList_f":
      " Direct Billing - you don’t need to pay out of pocket.",
    "step.first.insuresLinkList_s":
      "Already outside of your home country? No problem, you can still get coverage.",
    "step.first.insuresLinkList_t":
      "Get Medical Coverage for Pre-existing Conditions.",

    // =========================twoStep
    "step.two.title": "Purpose of Travels to Canada?",
    "step.two.treveler_1": "OUTDOOR ADVENTURE",
    "step.two.treveler_2": "VISITING FAMILY",
    "step.two.treveler_3": "STUDYING IN CANADA",
    "step.two.treveler_4": "EXPLORING OPPORTUNITIES",

    // ===============================threeStep

    "step.three.title": "Travel Plans?",
    "step.three.from": "TRAVELING FROM",
    "step.three.to": "VISITING PROVINCE",
    "step.three.departingDate": "POLICY START DATE",
    "step.three.returningDate": "POLICY END DATE",
    "step.three.selectCountry": "Select Country",
    "step.three.chooseProvince": "Choose Province(s)",

    "step.three.selectStatus": "Select Status",

    "step.three.policyNumber": "Policy number",

    // =========================fourStep
    "step.four.title": "Who is Traveling?",
    "step.four.travelersName_f": "TRAVELERS FIRST NAME",
    "step.four.travelersName_l": "TRAVELERS LAST NAME",
    "step.four.rStatus": "RELATIONSHIP STATUS",
    "step.four.travelersBirthDate": "TRAVELERS BIRTH DATE",
    "step.four.FName": "First Name",
    "step.four.LName": "Last Name",
    "step.four.month": "MM",
    "step.four.day": "DD",
    "step.four.year": "YYYY",
    "step.four.add": "ADD TRAVELER",

    // ==========================fiveStep

    "step.five.title": "Here’s Your Quote!",
    "step.five.string": "Choose Your Coverage Options then Select an Insurer",
    "step.five.btn": "I CHOOSE THIS POLICY",

    "step.five.Deductible": "Deductible",
    "step.five.LimitOptions": "Coverage",
    "step.five.MedicalCoverage": "Benefit Summary",
    "step.five.MedicalCoverageCovid": "NO COVID-19 COVERAGE",
    "step.five.OptionalMedical": "OPTIONAL MEDICAL",
    "step.five.OptionalCoverage": "OPTIONAL COVERAGE",

    "step.five.EmergencyMedical": "Emergency Medical",
    "step.five.EmergencyMedicalC": "Emergency Medical Coverage",
    "step.five.PreExisting": "Pre-existing Conditions",

    "step.five.PreExistingCoverage": "Pre-existing Medical Coverage",
    "step.five.nonMedicalCoverage": "Non Medical Coverage",
    "step.five.RentalCarProtection": "Rental Car Protection",
    "step.five.TripCTI": "Trip Cancellation & Trip Interruption",
    "step.five.TripInterruption": "Trip Interruption",

    "step.five.SportsCoverage": "Sports Coverage",
    "step.five.ContactSports": "Contact Sports - 18+",
    "step.five.AdventureSports": "Adventure Sports - All Ages",
    "step.five.ExtremeSport": "Extreme Sports - All Ages",
    "step.five.Baggae": "Baggae",

    "step.five.AccidentalDeth": "Accidental Death & Dismemberment",
    "step.five.VisitorsCanada": "Visitors to Canada Medical",

    "step.five.destination.nonMedicalTitle":
      "Pre-existing medical condition means a sickness, injury or medical condition, whether or not diagnosed by a physician",
    "step.five.destination.nonMedicalItems": `
           - for which you exhibited signs or symptoms; or
           - for which you required or received any medical consultation; and
           - which existed prior to the effective date of your coverage.`,

    "step.five.Baggage": "Baggage",
    "step.five.RentalCar": "Rental Car",
    "step.five.Sports": "Sports",
    cost: "Cost",

    "step.five.benefits": "Benefit",
    "step.five.details": "DESCRIPTION",
    "step.five.gms.Hospitalization": "Hospitalization",
    "step.five.gms.Hospitalization_c":
      "Covers hospital room and board charges, up to semi-private, unless specified by a physician (if medically necessary, charges for intensive and coronary care units are included)",
    "step.five.gms.MedicalServices": "Medical Services",
    "step.five.gms.MedicalServices_c":
      "Covers the cost of treatment by physician or surgeon",
    "step.five.gms.Diagnostic": "Diagnostic Services",
    "step.five.gms.Diagnostic_c":
      "Covers the cost of x-rays and other diagnostic tests",
    "step.five.gms.n_medical": "Out-patient Medical Treatment",
    "step.five.gms.n_medical_c":
      "Covers the cost of out-patient emergency room charges",
    "step.five.gms.n_hospita": "In-Hospital Prescription Drugs",
    "step.five.gms.n_hospita_c":
      "Covers the cost of prescription drugs and medication prescribed by a physician (to a maximum of a 30 day prescription)",
    "step.five.gms.Road": "Road Ambulance",
    "step.five.gms.Road_c":
      "Covers 100% of the cost for emergency transport to a hospital",
    "step.five.gms.Paramedical": "Paramedical Services",
    "step.five.gms.Paramedical_c":
      "Covers up to $300 toward the cost of emergency services of an osteopath, physiotherapist, chiropractor, chiropodist, and/or podiatrist",
    "step.five.gms.Accidental": "Accidental Dental",
    "step.five.gms.Accidental_c":
      "Covers up to $2,000 per person and up to $250 for the relief of dental pain",
    "step.five.gms.Return": "Return of Remains",
    "step.five.gms.Return_c":
      "Covers the cost for preparation and transportation to your country of origin, destination in Canada, or the cost of cremation or burial at the place of death",

    "step.five.gms.Child": "Child Care",
    "step.five.gms.Child.c":
      "Covers the cost for licensed child care for a dependant traveling with you if you are hospitalized (up to $500)",

    "step.five.gms.Repar": "Repatriation companion",
    "step.five.gms.Repar.c":
      "Up to $5,000 to transport you home for further medical treatment with pre-approval by GMS",

    "step.five.gms.Oop": "Out-of-Pocket Expenses",
    "step.five.gms.Oop.c":
      "Covers the cost of accommodations, meals, phone calls and taxi or bus fares incurred by an accompanying family member in the event hospitalization continues after the policy expiry date",

    "step.five.gms.Assist": "24-Hour Travel Assistance Services",
    "step.five.gms.Assist.c":
      "Coordination of all medical care, transportation, and repatriation; telephone interpretation services in most languages; monitoring of progress during treatment and recovery by managed care",

    "step.five.gms.tBenefits": "Travel Benefits Outside of Canada",
    "step.five.gms.tBenefits.c":
      "Benefits apply to side-trips outside of Canada of up to 30 days each, excluding visits to your country of origin Air Ambulance - charges to transport you back to your destination in Canada or your country of origin. Special Attendant - reimbursement of a one-way trip economy airfare ticket for a medical attendant to accompany you back to your destination in Canada or country of origin for further in-hospital treatment Escort of Insured Children - reimbursement of the cost of a one-way trip economy airfare to return an accompanying child or children and escort, when necessary, back to your original point of departure",

    // "step.five.gms.otBenefits": "Travel Benefits Outside of Canada",
    // "step.five.gms.otBenefits.c": "Benefits apply to side-trips outside of Canada of up to 30 days each, excluding visits to your country of origin Air Ambulance - charges to transport you back to your destination in Canada or your country of origin. Special Attendant - reimbursement of a one-way trip economy airfare ticket for a medical attendant to accompany you back to your destination in Canada or country of origin for further in-hospital treatment Escort of Insured Children - reimbursement of the cost of a one-way trip economy airfare to return an accompanying child or children and escort, when necessary, back to your original point of departure",

    "step.five.dest.Maximum": "Maximum Sum Insured Options",
    "step.five.dest.EmergencyHosp": "Emergency Hospital",
    "step.five.dest.Semi": "Semi-private hospital accommodation",
    "step.five.dest.EmergencyMed": "Emergency Medical",
    "step.five.dest.Services": "Services of physician, surgeon, anesthetist",
    "step.five.dest.Services_1": "Services of physician, surgeon, anesthetist",
    "step.five.dest.Services_2": "Diagnostics, lab tests and/or x-rays;",
    "step.five.dest.Services_3":
      "Licensed local air, land or sea ambulance to the nearest hospital",
    "step.five.dest.Services_4":
      "Private duty services of a registered graduate nurse up to $10,000;",
    "step.five.dest.Services_5": "Rental of medical appliances;",

    "step.five.dest.Drugs": "Drugs or Medications",
    "step.five.dest.UpTo": "Up to $1,000 not exceeding 30-day supply",
    "step.five.dest.ProfessionalServ": "Professional Services",
    "step.five.dest.Physiotherapist":
      "Physiotherapist, chiropractor, chiropodist, osteopath, podiatrist when ordered by the attending physician up to $500 per practitioner for out-patient treatment",
    "step.five.dest.EmergencyAir": "Emergency Air Transportation / Return home",
    "step.five.dest.WhenPre": "When pre-approved by Active Care Management",
    "step.five.dest.Transportation": "Transportation of Family",
    "step.five.dest.ToTran":
      "Up to $3,000 to transport one family member or close friend and up to $1,000 for meals and accommodation",
    "step.five.dest.PreExisting": "Pre-existing Conditions",
    "step.five.dest.Age": "Age 0 to 79: ",
    "step.five.dest.Option_1":
      "Option 1: Covered only if stable during the 120 days prior to the effective date ",
    "step.five.dest.Option_2":
      "Option 2: All pre-existing conditions will be excluded from coverage Age 80 and over – all pre-existing conditions are excluded from coverage",

    "step.five.dest.FamTrans": "Transportation of Family",
    "step.five.dest.FamTransDescr":
      "Up to $3,000 to transport one family member or close friend and up to $1,000 for meals and accommodation",
    "step.five.dest.FolowUp": "Follow-up Visits",
    "step.five.dest.FolowUpDescr": "Up to $3,000 CAD for follow-up visits",

    "step.five.dest.Accidental": "Accidental Dental",
    "step.five.dest.AccidentalDescr":
      "Up to $3,000 CAD for emergency treatment for accidental blow to the face",

    "step.five.dest.DentalEmerg": "Dental Emergencies",
    "step.five.dest.DentalEmergDescr":
      "Up to $3,000 CAD for emergency treatment for accidental blow to the face",

    "step.five.dest.Meals": "Meals and Accommodation companion ",
    "step.five.dest.MealsDescr":
      "Up to $150 CAD per day to a maximum of CAD1,500 when hospitalized",

    "step.five.dest.Emergency Return Home": "Emergency Return Home ",
    "step.five.dest.Emergency Return HomeDescr":
      "Up to $3,000 CAD for the additional cost of a one-way economy transportation",

    "step.five.dest.Deceased": "Return of Deceased ",
    "step.five.dest.DeceasedDescr":
      "Up to CAD10,000 for return or remains or up to CAD4,000 for cremation or burial at place of death",

    "step.five.dest.Death": "Accidental Death & Dismemberment ",
    "step.five.dest.DeathDescr":
      "Up to the maximum sum of insured not to exceed of CAD150,000 for loss of life, limb or sight resulting from an accidental injury",

    "step.five.dest.Flight": "Flight Accident ",
    "step.five.dest.FlightDescr": "Up to a maximum sum of insured of CAD50,000",

    "step.five.dest.Lim": "Limitations and Exclusions ",
    "step.five.dest.LimDescr":
      "Refer to policy wording for a complete list of limitations and exclusions",

    "step.five.dest.DeductibleOptions": "Deductible Options ",
    "step.five.dest.DeductibleOptionsDescr":
      "Age 0-85, 0 deductible <br/>\nAge 86 and over, 500 deductible",

    "step.five.dest.WaitingPeriod": "Waiting Period ",
    "step.five.dest.WaitingPeriodDescr":
      "if the insurance is purchased prior to the arrival date in Canada, no waiting period. If the insurance is purchased after the arrival date in Canada, 48 hours waiting period applicable to sickness (age 0 to 85). 15 days waiting period applicable to sickness (age 86 and over). The waiting period may be waived if you have insurance at the time of purchase. ",

    "step.five.dest.Limitations": "Limitations & Exclusions",
    "step.five.dest.DeductOp": "Deductible Options",
    "step.five.dest.YourPolicy":
      "Your policy contains certain limitations and exclusions. Please refer to policy wording for a complete list of the limitations and exclusions",
    "step.five.dest.AgeUp": `-Age up to 85 / $0 deductible (deductible options are available) -Age 86 and over / $500 deductible`,

    "step.five.dest.can": "Destination: Canada - Eligibility: Requirements",
    "step.five.dest.can1": "Coverage is NOT AVAILABLE to any individual who:",
    "step.five.dest.can2": "has been diagnosed with a terminal illness;",
    "step.five.dest.can3":
      "has Acquired Immune Deficiency Syndrome (AIDS) or Human Im",
    "step.five.dest.can4": "munodeficiency Virus (HIV);",
    "step.five.dest.can5":
      "has been prescribed or used home oxygen treatment in the last 12 months;",
    "step.five.dest.can6":
      "has been diagnosed with or treated for congestive heart failure;",
    "step.five.dest.can7":
      "has had a major organ transplant (heart, kidney, liver, lung);",
    "step.five.dest.can8":
      "has received kidney dialysis treatment in the last 12 months.",
    "step.five.dest.can9": "To be eligible for coverage you must:",
    "step.five.dest.can10": "be at least 15 days old;",
    "step.five.dest.can11":
      "on the effective date, not be insured or eligible for benefits under a Canadian government health insurance plan;",
    "step.five.dest.can12":
      "be in good health at the time you purchase your policy and on the  effective date, and know of no reason why you would attend any  medical consultation during the period of coverage.",

    "step.five.dest.WaitingPeriod": "Waiting Period",
    "step.five.dest.Dest_1": "Age 0 to 85",
    "step.five.dest.Dest_2":
      "If the insurance was purchased prior to your arrival date in Canada, the “waiting period” does not apply or,",
    "step.five.dest.Dest_3":
      "If this insurance is purchased any time after your arrival in Canada, then in respect of any sickness you will only be entitled to receive benefits for the cost of eligible medical expenses incurred after the first 48 hours from the effective date of the policy.",
    "step.five.dest.Dest_4": "Age 86 and over",
    "step.five.dest.Dest_5":
      "If the insurance was purchased prior to arrival date in Canada, the “waiting period” does not apply.",
    "step.five.dest.Dest_6":
      "If the insurance is purchased after your arrival date in Canada, then in respect of any sickness you will only be entitled to receive benefits for the cost of eligible medical expenses incurred after fifteen (15) days from the effective date of this policy.",
    "step.five.dest.Dest_7": "The waiting period may be waived if:",
    "step.five.dest.Dest_8":
      "This policy is purchased on or prior to the expiry date of an existing Destination: Canada policy.",
    "step.five.dest.Dest_9":
      "If you have insurance with another insurer during the first part of your trip in Canada, and you are purchasing this insurance after your arrival in Canada and there will be no gap in coverage. You must provide satisfactory proof that you have other coverage in force and receive a written approval from the Insurer.",

    "step.five.trav.Maximum": "Maximum Duration	",
    "step.five.trav.EmergencyHosp": "Emergency Hospital",
    "step.five.trav.Semi": "Semi-private hospital accommodation",
    "step.five.trav.EmergencyMed": "Emergency Medical",
    "step.five.trav.Services": "Services of physician, surgeon, anesthetist",
    "step.five.trav.Services_1": "Services of physician, surgeon, anesthetist",
    "step.five.trav.Services_2": "Diagnostics, lab tests and/or x-rays;",
    "step.five.trav.Services_3":
      "Licensed local air, land or sea ambulance to the nearest hospital",
    "step.five.trav.Services_4":
      "Private duty services of a registered graduate nurse up to $10,000;",
    "step.five.trav.Services_5": "Rental of medical appliances;",

    "step.five.trav.Drugs": "Drugs or Medications",
    "step.five.trav.UpTo": "Up to $1,000 not exceeding 30-day supply",
    "step.five.trav.ProfessionalServ": "Professional Services",
    "step.five.trav.Physiotherapist":
      "Physiotherapist, chiropractor, chiropodist, osteopath, podiatrist when ordered by the attending physician up to $500 per practitioner for out-patient treatment",
    "step.five.trav.EmergencyAir": "Emergency Air Transportation / Return home",
    "step.five.trav.WhenPre": "When pre-approved by Active Care Management",
    "step.five.trav.Transportation": "Transportation of Family",
    "step.five.trav.ToTran":
      "Up to $3,000 to transport one family member or close friend and up to $1,000 for meals and accommodation",
    "step.five.trav.PreExisting": "Pre-existing Conditions",
    "step.five.trav.Age": "Age 0 to 79: ",
    "step.five.trav.Option_1":
      "Option 1: Covered only if stable during the 120 days prior to the effective date ",
    "step.five.trav.Option_2":
      "Option 2: All pre-existing conditions will be excluded from coverage Age 80 and over – all pre-existing conditions are excluded from coverage",

    "step.five.trav.FamTrans": "Transportation of Family",
    "step.five.trav.FamTransDescr":
      "Up to $3,000 to transport one family member or close friend and up to $1,000 for meals and accommodation",
    "step.five.trav.FolowUp": "Follow-up Visits",
    "step.five.trav.FolowUpDescr": "Up to $3,000 CAD for follow-up visits",

    "step.five.trav.Accidental": "Accidental Dental",
    "step.five.trav.AccidentalDescr":
      "Up to $3,000 CAD for emergency treatment for accidental blow to the face",

    "step.five.trav.DentalEmerg": "Dental Emergencies",
    "step.five.trav.DentalEmergDescr":
      "Up to $3,000 CAD for emergency treatment for accidental blow to the face",

    "step.five.trav.Meals": "Meals and Accommodation companion ",
    "step.five.trav.MealsDescr":
      "Up to $150 CAD per day to a maximum of CAD1,500 when hospitalized",

    "step.five.trav.Emergency Return Home": "Emergency Return Home ",
    "step.five.trav.Emergency Return HomeDescr":
      "Up to $3,000 CAD for the additional cost of a one-way economy transportation",

    "step.five.trav.Deceased": "Return of Deceased ",
    "step.five.trav.DeceasedDescr":
      "Up to CAD10,000 for return or remains or up to CAD4,000 for cremation or burial at place of death",

    "step.five.trav.Death": "Accidental Death & Dismemberment ",
    "step.five.trav.DeathDescr":
      "Up to the maximum sum of insured not to exceed of CAD150,000 for loss of life, limb or sight resulting from an accidental injury",

    "step.five.trav.Flight": "Flight Accident ",
    "step.five.trav.FlightDescr": "Up to a maximum sum of insured of CAD50,000",

    "step.five.trav.Lim": "Limitations and Exclusions ",
    "step.five.trav.LimDescr":
      "Refer to policy wording for a complete list of limitations and exclusions",

    "step.five.trav.DeductibleOptions": "Deductible Options ",
    "step.five.trav.DeductibleOptionsDescr":
      "Age 0-85, 0 deductible <br/>\nAge 86 and over, 500 deductible",

    "step.five.trav.WaitingPeriod": "Waiting Period ",
    "step.five.trav.WaitingPeriodDescr":
      "if the insurance is purchased prior to the arrival date in Canada, no waiting period. If the insurance is purchased after the arrival date in Canada, 48 hours waiting period applicable to sickness (age 0 to 85). 15 days waiting period applicable to sickness (age 86 and over). The waiting period may be waived if you have insurance at the time of purchase. ",

    "step.five.trav.Limitations": "Limitations & Exclusions",
    "step.five.trav.DeductOp": "Deductible Options",
    "step.five.trav.YourPolicy":
      "Your policy contains certain limitations and exclusions. Please refer to policy wording for a complete list of the limitations and exclusions",
    "step.five.trav.AgeUp": `-Age up to 85 / $0 deductible (deductible options are available) -Age 86 and over / $500 deductible`,

    "step.five.trav.can": "Destination: Canada - Eligibility: Requirements",
    "step.five.trav.can1": "Coverage is NOT AVAILABLE to any individual who:",
    "step.five.trav.can2": "has been diagnosed with a terminal illness;",
    "step.five.trav.can3":
      "has Acquired Immune Deficiency Syndrome (AIDS) or Human Im",
    "step.five.trav.can4": "munodeficiency Virus (HIV);",
    "step.five.trav.can5":
      "has been prescribed or used home oxygen treatment in the last 12 months;",
    "step.five.trav.can6":
      "has been diagnosed with or treated for congestive heart failure;",
    "step.five.trav.can7":
      "has had a major organ transplant (heart, kidney, liver, lung);",
    "step.five.trav.can8":
      "has received kidney dialysis treatment in the last 12 months.",
    "step.five.trav.can9": "To be eligible for coverage you must:",
    "step.five.trav.can10": "be at least 15 days old;",
    "step.five.trav.can11":
      "on the effective date, not be insured or eligible for benefits under a Canadian government health insurance plan;",
    "step.five.trav.can12":
      "be in good health at the time you purchase your policy and on the  effective date, and know of no reason why you would attend any  medical consultation during the period of coverage.",

    "step.five.trav.WaitingPeriod": "Waiting Period",
    "step.five.trav.Dest_1": "Age 0 to 85",
    "step.five.trav.Dest_2":
      "If the insurance was purchased prior to your arrival date in Canada, the “waiting period” does not apply or,",
    "step.five.trav.Dest_3":
      "If this insurance is purchased any time after your arrival in Canada, then in respect of any sickness you will only be entitled to receive benefits for the cost of eligible medical expenses incurred after the first 48 hours from the effective date of the policy.",
    "step.five.trav.Dest_4": "Age 86 and over",
    "step.five.trav.Dest_5":
      "If the insurance was purchased prior to arrival date in Canada, the “waiting period” does not apply.",
    "step.five.trav.Dest_6":
      "If the insurance is purchased after your arrival date in Canada, then in respect of any sickness you will only be entitled to receive benefits for the cost of eligible medical expenses incurred after fifteen (15) days from the effective date of this policy.",
    "step.five.trav.Dest_7": "The waiting period may be waived if:",
    "step.five.trav.Dest_8":
      "This policy is purchased on or prior to the expiry date of an existing Destination: Canada policy.",
    "step.five.trav.Dest_9":
      "If you have insurance with another insurer during the first part of your trip in Canada, and you are purchasing this insurance after your arrival in Canada and there will be no gap in coverage. You must provide satisfactory proof that you have other coverage in force and receive a written approval from the Insurer.",

    "step.five.tugo.medicalCoverage_1": "Emergency Medical Insurance",
    "step.five.tugo.medicalCoverage_2":
      "Protect yourself against unexpected illnesses or injuries while you’re away from home with Emergency Medical Insurance. Available as a Single or Multi Trip policy, it’s ideal for Canadian residents travelling outside of their home province or visitors to Canada. This insurance lets you travel with confidence, knowing you’ll be protected when you need it most. From hospital treatment, to emergency dental care, to return of an accompanying pet, travel medical insurance for emergencies is the way to go!",
    "step.five.tugo.medicalCoverage_3":
      " Available for all ages (except All Inclusive Holiday Package)",
    "step.five.tugo.medicalCoverage_4":
      "Hospital confinement and treatment including: - Doctor’s services; - Ambulance services; - X-rays and other diagnostic laboratory tests; - Prescription drugs; - Essential medical supplies such as crutches.",
    "step.five.tugo.medicalCoverage_5": "Fracture treatment benefit",
    "step.five.tugo.medicalCoverage_6":
      "Other professional medical services like physio, etc. to relieve an acute emergency",
    "step.five.tugo.medicalCoverage_7": "Emergency Dental Services",
    "step.five.tugo.medicalCoverage_8":
      "Hospital allowance for incidental charges like TV rental, etc.",
    "step.five.tugo.medicalCoverage_9":
      "Emergency air transportation (must be pre-approved)",
    "step.five.tugo.medicalCoverage_10": "Return of excess baggage",
    "step.five.tugo.medicalCoverage_11": "Return of travelling companion",
    "step.five.tugo.medicalCoverage_12": "Return of children/ grandchildren",
    "step.five.tugo.medicalCoverage_13": "Family transportation",
    "step.five.tugo.medicalCoverage_14":
      "Out of pocket expenses for accommodation, meals etc.",
    "step.five.tugo.medicalCoverage_15":
      "Return of vehicle to your home province or nearest rental place",
    "step.five.tugo.medicalCoverage_16":
      "Return to your destination if you had to be air evacuated to your home province for care ",
    "step.five.tugo.medicalCoverage_17": "Return of accompanying pet",

    "step.five.tugo.luggage_1": "Luggage Insurance",
    "step.five.tugo.luggage_2":
      "Available as an add-on optional coverage to a Single Trip plan or package, Baggage Insurance plan is ideal for Canadian residents travelling outside of their home provinces and visitors to Canada",
    "step.five.tugo.luggage_3":
      "Payment for lost, damaged, destroyed or stolen baggage and personal effects, anywhere in the world, on water, land or in the air",
    "step.five.tugo.luggage_4":
      "Coverage for stolen currency due to theft or robbery, up to the benefit limit",
    "step.five.tugo.luggage_5":
      "Payment for personal necessities if your baggage is delayed, up to the benefit limit",

    "step.five.tugo.rental_1": "Rental Car Protection Insurance",
    "step.five.tugo.rental_2":
      "Available as an add-on optional coverage to a Single Trip plan or package, Rental Car coverage is perfect for Canadian residents and visitors to Canada. Protect your rental vehicle against damage or loss whether you’re travelling in the next province or abroad.",
    "step.five.tugo.rental_3": "Physical damage to a leased/rented automobile",
    "step.five.tugo.rental_4": "Loss of a leased/rented automobile",
    "step.five.tugo.rental_5":
      "Towing, salvage, fire department charges, custom duties, and reasonable loss of use",

    "step.five.tugo.sport_1": "Optional Sports Coverages",
    "step.five.tugo.sport_2":
      "At TuGo, we’ve got you covered, even for extreme activities! You can hike, zipline, bungee   jump or even parasail over water and still be protected under our standard Emergency Medical Insurance or Visitor to Canada Emergency Medical Insurance. And for you more adventurous types, take your travels to the extreme, with our Optional Sports Coverages.    ",
    "step.five.tugo.sport_3":
      "For an additional Single Trip or Multi Trip fee, our optional coverages can protect you, regardless of whether you’re an amateur or professional",

    "step.five.tugo.sport_4": "Contact Sports Coverage",
    "step.five.tugo.sport_5":
      "Love a little competition? Whether you’re on the field or in the ring, Contact Sports Coverage   protects you while you’re travelling outside of your home province or country of permanent residence. This insurance coverage applies when travellers (ages 18+) participate in, coach, teach, train or practice on behalf of a registered team, league, association, club or while competing in a registered tournament, competition or sporting event. If travellers are under 18 years old, they won’t need this optional coverage. With TuGo, their Emergency Medical Insurance or visitor to Canada Emergency Medical Insurance will cover them in a medical emergency while away!",
    "step.five.tugo.sport_6": "Sports/Activity",
    "step.five.tugo.sport_7": "Is it covered?",
    "step.five.tugo.sport_8": "Australian Football",
    "step.five.tugo.sport_9": "Boxing",
    "step.five.tugo.sport_10": "Football",
    "step.five.tugo.sport_11": "Ice Hockey",
    "step.five.tugo.sport_12": "Lacrosse",
    "step.five.tugo.sport_13": "Rugby",

    "step.five.tugo.sport_14": "Adventure. Sports Coverage",
    "step.five.tugo.sport_15":
      "For adventure-seekers who love exploring the great outdoors and testing their physical limits, this coverage applies when you participate in, coach, teach, train or practice the following sports and activities",
    "step.five.tugo.sport_16": "Backcountry Skiing",
    "step.five.tugo.sport_17": "Backcountry Snowboarding",
    "step.five.tugo.sport_18": "Backcountry Snowshoeing",
    "step.five.tugo.sport_19": "Bobsledding",
    "step.five.tugo.sport_20": "Canyoning/Canyoneering",
    "step.five.tugo.sport_21": "Downhill Freestyle Skiing",
    "step.five.tugo.sport_22": "Downhill Snowboarding in Organized Contests",
    "step.five.tugo.sport_23": "Downhill Longboarding",
    "step.five.tugo.sport_24": "Downhill Mountain Biking",
    "step.five.tugo.sport_25": "Downhill Skating",
    "step.five.tugo.sport_26": "Endurance Activities over 6 hours",
    "step.five.tugo.sport_27": "Mountaineering up to 6,000m",
    "step.five.tugo.sport_28":
      "Flying as a pilot or passenger in a glider or ultralight",
    "step.five.tugo.sport_29": "Hang Gliding",
    "step.five.tugo.sport_30": "High Risk Snowmobiling",
    "step.five.tugo.sport_31": "Ice Climbing",
    "step.five.tugo.sport_32": "Luge/Skeleton",
    "step.five.tugo.sport_33":
      "Non-motorized X Game Sports (or those sports in similar type events)",
    "step.five.tugo.sport_34":
      "Parachuting/Skydiving/Tandem Skydiving (more than one jump per trip)",
    "step.five.tugo.sport_35": "Paragliding/Parapenting",
    "step.five.tugo.sport_36": "Paramotoring",
    "step.five.tugo.sport_37": "Snow Kiting",
    "step.five.tugo.sport_38": "Stunt/Aerobatic Flying",

    "step.five.tugo.sport_39": "Extreme Sports Coverage",
    "step.five.tugo.sport_40":
      "Love living life to the limit? Coverage is available for extreme sports enthusiasts like you, who 			    live for a thrill! This insurance applies when you participate in, coach, teach, train or practice 			    the following sports and activities:    ",
    "step.five.tugo.sport_41":
      "Scuba Diving (if not certified by an internationally recognized and accepted program)    ",
    "step.five.tugo.sport_42": "Scuba Diving or Free Diving over 30m",
    "step.five.tugo.sport_43": "Base Jumping",
    "step.five.tugo.sport_44": "Bull Riding/Bull Fighting",
    "step.five.tugo.sport_45": "Rodeo",
    "step.five.tugo.sport_46": "Motorized Speed Contests",
    "step.five.tugo.sport_47":
      "Motorized X Game Sports (or those sports in similar type events)",
    "step.five.tugo.sport_48": "Mountaineering over 6,000m",
    "step.five.tugo.sport_49": "Ultimate Fighting & Mixed Martial Arts",
    "step.five.tugo.sport_50": "Wingsuit Jumping/Wingsuit Flying",

    "step.five.tugo.sport_51": "Excluded Activities",
    "step.five.tugo.sport_52":
      "Whatever sport you’re into, be sure that it’s covered. If it’s not listed above under Contact 			    Sports, Adventure Sports or Extreme Sports, we may cover it under our standard Emergency 			    Medical Insurance, unless it’s listed below as an excluded activity:",
    "step.five.tugo.sport_53":
      "Out-of-bounds skiing, snowboarding, snowshoeing, or non-motorized snow-biking",
    "step.five.tugo.sport_54":
      "Any sports or activities listed in the Sports and Activities Optional Coverages unless you’ve 			    bought the applicable optional coverage",

    "step.five.tugo.maximum": "Maximum Liability",
    "step.five.tugo.maximum.c": "$200,000 CAD",

    "step.five.tugo.medicalT": "Emergency medical treatment",
    "step.five.tugo.medicalT.1":
      "Emergency medical treatment for sickness or injury whether in-patient or out-patient",
    "step.five.tugo.medicalT.2": "Services of physician ",
    "step.five.tugo.medicalT.3": "Private duty nursing ",
    "step.five.tugo.medicalT.4": "X-rays and laboratory services ",
    "step.five.tugo.medicalT.5":
      "Rental or purchase of essential medical appliances",

    "step.five.tugo.folowup": "Follow-up visits",
    "step.five.tugo.folowup.c":
      "Three follow up visits within 14 days of the initial emergency medical treatment",

    "step.five.tugo.amb": "Ambulance",
    "step.five.tugo.amb.c":
      "Yes, ground, sea and air including paramedics or taxi in lieu",

    "step.five.tugo.Prescription": "Prescription drugs ",
    "step.five.tugo.Prescription.c": "Limit of a 30-day supply",

    "step.five.tugo.profmed": "Professional medical services ",
    "step.five.tugo.profmed.c":
      "Up to $500 per practitioner including a licensed physiotherapist, chiropractor, chiropodist, osteopath, podiatrist and optometrist",

    "step.five.tugo.Fracture": "Fracture treatment ",
    "step.five.tugo.Fracture.c":
      "Up to $1,000 for medical treatment related to fractures, including x-rays, cast removal, re-casting and physiotherapy",

    "step.five.tugo.howAllow": "Hospital allowance ",
    "step.five.tugo.howAllow.c":
      "Up to $75 per day for incidental hospital charges",

    "step.five.tugo.airTrans": "Emergency air transportation ",
    "step.five.tugo.airTrans.c":
      "Up to Policy limit; includes cost of air ambulance, stretcher fare or one-way economy airfare, medical attendant or travelling companion, seat upgrade when medically necessary (for insured and attendant or travelling  companion)",

    "step.five.tugo.Rettrav": "Return of travelling companion ",
    "step.five.tugo.Rettrav.c":
      "A one-way economy airfare back to the departure point if the insured is returned home for medical reasons",

    "step.five.tugo.childReturn": "Return of children / grandchildren",
    "step.five.tugo.childReturn.c":
      "If the insured is returned under the Air Transportation benefit, or Repatriation benefit; a one-way economy airfare to return dependent children/grandchildren back to original departure point and cost of chaperone Applies to children/grandchildren ages 21 or 25 if full-time student; no age limit for mentally/physically handicapped",

    "step.five.tugo.repartation": "Repatriation",
    "step.five.tugo.repartation.1":
      " Up to policy limit for preparation and return of body ",
    "step.five.tugo.repartation.2": "Burial up to $5,000",
    "step.five.tugo.repartation.3":
      "Cremation up to $5,000 includes cost to return ashes to country of permanent residence",
    "step.five.tugo.repartation.4":
      "Transportation costs for one family member to identify the body, and up to $300 per day to a maximum of $1,500 for meals and accommodation, the family member will also be covered as an insured",

    "step.five.tugo.famTrans": "Family transportation ",
    "step.five.tugo.famTrans.c":
      "One economy return airfare or ground transportation costs and up to $300 per day to a maximum of $1,500 for out-of-pocket expenses",

    "step.five.tugo.ChildCare": "Child Care",
    "step.five.tugo.ChildCare.c":
      "Up to $250 per day to a maximum of $2,500 for child care costs for insured children 18 years and under when insured is confined to hospital or transferred to a different hospital in another city for emergency medical treatment",

    "step.five.tugo.oop": "Out-of-pocket expenses companion ",
    "step.five.tugo.oop.c": "Up to $250 per day to a maximum of $2,500",

    "step.five.tugo.Dental": "Dental",
    "step.five.tugo.Dental.1": "Up to $4,000 for accident",
    "step.five.tugo.Dental.2": "Up to $500 for any other dentai emergencies",

    "step.five.tugo.24hacc": "24-hour accident insurance ",
    "step.five.tugo.24hacc.c": "Up to $25,000",

    "step.five.tugo.sum": "Sum insured options ",
    "step.five.tugo.sum.c":
      "$25,000 / $50,000 / $100,000 / $150,000 / $200,000",

    "step.five.tugo.Rates": "Rates",
    "step.five.tugo.Rates.c":
      "Single person, two person, family rates available",

    "step.five.tugo.Deductible": "Deductible ",
    "step.five.tugo.Deductible.c":
      "$150 CAD automatic; option to buy out deductible at a 15% surcharge; other options available for a premium discount",

    "step.five.tugo.waiting": "Waiting period ",
    "step.five.tugo.waiting.1":
      "No waiting period on sickness if policy is purchased prior to arrival in Canada ",
    "step.five.tugo.waiting.2":
      " 48-hour waiting period on sickness if policy is purchased within 60 days  of arrival in Canada",
    "step.five.tugo.waiting.3":
      "7-day waiting period on sickness if policy is purchased 61 days or more  after arrival in Canada",

    "step.five.tugo.TravelW": "Travel worldwide ",
    "step.five.tugo.TravelW.c":
      "Travel worldwide and visits to country of permanent residence are valid as long as majority of time is spent in Canada; no coverage provided while in country of permanent residence",

    "step.five.tugo.prex": "Pre-existing conditions coverage ",
    "step.five.tugo.prex.1[title]": "59 years and under",
    "step.five.tugo.prex.2":
      "120 days of stability period prior to the effective date or arrival date in Canada – whichever occurs later 60 to 69 years",
    "step.five.tugo.prex.3":
      "180-day stability period prior to the effective date or arrival date in   Canada – whichever occurs later 70 years and over",
    "step.five.tugo.prex.4": "Pre-existing conditions are not covered",

    "step.five.tugo.Exclusions": "Exclusions",
    "step.five.tugo.Exclusions.1": "Non-emergency treatment",
    "step.five.tugo.Exclusions.2": "Long term or ongoing care",
    "step.five.tugo.Exclusions.3":
      "Test and investigative consultation Refer to the policy wording for a complete list of exclusions",

    // ==========================================stepSix
    "step.six.title": "Review Your Policy.",
    "step.six.subtitle": "Once you’re happy, purchase your policy!",
    "step.six.tab_btn_1": "Travel Plan",
    "step.six.tab_btn_2": "Travellers",
    "step.six.tab_btn_3": "Coverage",

    "step.six.travelers": "Travellers",
    "step.six.relationship": "Relationship",
    "step.six._age": "age",

    "step.six.trip_length": "TRIP LENGTH",
    "step.six.days": "days",
    "step.six.deductible": "Deductible",
    "step.six.coverage": "Coverage",

    // ==========================contactDetailStep

    "step.contactDetail.title": "Almost Done!",
    "step.contactDetail.string":
      "Enter your contact details, to receive your quote.",
    "step.contactDetail.email": "Email",
    "step.contactDetail.email2": "Confirm your email",
    "step.contactDetail.phone": "Phone",
    "step.contactDetail.phoneor": "Phone or WeChat",
    "step.contactDetail.btn": "GET MY QUOTE",

    // ==========================paymentStep

    "payment.Fname": "Card Holder's First Name",
    "payment.Lname": "Card Holder's Last Name",
    "payment.title": "Purchase Your Policy.",
    "payment.subtitle": "Finalize your policy by purchasing now.",
    "payment.full_address": "Full Residence Address",
    "payment.card_num": "CREDIT CARD NUMBER",
    "payment.card_date": "EXPIRY DATE",
    "payment.btn": "PURCHASE MY POLICY",
    "payment.processing": "Processing...",

    //=============================completeStep
    "step.complite.title": "Your Policy is Now Created",
    "step.complite.string":
      "You will receive an email from iWareTravel and the Insurer with your policy information",
    "step.complite.btn": "Return",

    // ==============================firstPage Screen2
    "fp.screenTwo.title": "Why Get Travel Insurance?",
    "fp.screenTwo.titleTab1":
      "FOR COVERAGE OF PRE-EXISTING MEDICAL CONDITIONS.",
    "fp.screenTwo.titleTab2":
      "ALREADY STARTED YOUR TRAVELS? YOU CAN STILL GET TRAVEL INSURANCE!",
    "fp.screenTwo.titleTab3": "COVERAGE FOR LOST LUGGAGE.",
    "fp.screenTwo.titleTab4": "COVERAGE FOR MISSED FLIGHTS.",
    "fp.screenTwo.titleTab5": "COVERAGE FOR TRIP CANCELLATIONS.",

    "fp.screenTwo.fearFormTitle": "What’s your biggest travel fear?",
    "fp.screenTwo.submit": "Submit",
    "fp.screenTwo.selectAnsver": "Select your answer",

    "fp.screenTwo.fear5": "Forgot my pills for pre-existing conditions",
    "fp.screenTwo.fear3": "Missing Flights or Luggage",
    "fp.screenTwo.fear1": "For coverage of pre-existing conditions.",
    "fp.screenTwo.fear4": "A big medical bill paid out of my own pocket",
    "fp.screenTwo.fear2":
      "Alredy started your travels? You can still travel insurance!",

    "fp.screenTwo.fear_info_text1":
      "Worried about your pre-existing conditions (e.g. heart conditions, high-blood pressure, etc)?  Get the pre-existing conditions coverage. Some companies like Manulife and Destination Travel offer both options with and without stable pre-existing condition coverage. Other companies (such as GMS) offer only one policy with stable pre-existing condition coverage up to a certain age. Our online purchasing process will guide you through.",
    "fp.screenTwo.fear_info_text2":
      "Not eligible to buy travel insurance from your home country anymore? Don’t worry, we have your back. Buy anytime before or even after you started your trip in Canada using our platform. A waiting period may apply when you buy after your trip started.",
    "fp.screenTwo.fear_info_text3":
      "We understand the frustration of losing your luggage while travel, especially when you travel with expensive stuff. Some companies (such as Tugo) offer add-on for lost luggage coverage.",
    "fp.screenTwo.fear_info_text4":
      "Worried about stuck in somewhere or missing connection flights? You need the trip interruption coverage. Reminder: only non-refundable travel costs can be reimbursed.",
    "fp.screenTwo.fear_info_text5":
      "Had to cancel your trip due to job loss or sickness, etc.? the trip cancellation coverage will help you recover the non-refundable, non-recoverable prepaid travel costs.",

    // ==============================firstPage Screen3
    "fp.screenThree.title": "What Our Customers",
    "fp.screenThree.subtitle": "Are Saying About Us.",

    // ==============================firstPage Screen4
    "fp.screenFour.title": "About Us",
    "fp.screenFour.btn": "GET A FREE QUOTE NOW",
    "fp.screenFour.bold":
      "As an immigrant to Canada, I have family visiting me each year. One year, my dad was hospitalized for three days while visiting Canada, costing $13,000. Luckily I had bought travel insurance for him. It was the best investment I’ve ever made.",
    "fp.screenFour.norm":
      "Did you know, you can buy Travel Insurance from either your home country or your destination country? I learnt that it’s always best to buy travel insurance from your destination country. In Canada for example, you can get coverage for pre-existing medical conditions and receive better service, such as direct billing, which means you don’t need to pay out of your own pocket in the case of medical emergency.",

    // ==============================contact us page

    "contactUs.title": "Need to Connect With us?",
    "contactUs.subTitle": "",
    "contactUs.formTitle": "PLEASE SUBMIT THIS FORM TO CONNECT WITH US",
    "contactUs.fn": "*First Name",
    "contactUs.ln": "*Last Name",
    "contactUs.email": "*Email",
    "contactUs.message": "Your message",
    "contactUs.send": "SEND MESSAGE",

    // ==============================claim page

    "claim.title": "Have an emergency?",
    "claim.subtitle": "We are sorry to hear that, but you’re in good hands!",
    "claim.text":
      "In the event of an emergency, you MUST call your insurance company as soon as possible so as not to negate or reduce any allowable coverage defined in your policy. All the necessary information (such as your policy number, contact information of the insurance company) can be found on the wallet card.",
    "claim.call": "FOR EMERGENCY TUGO CLAIMS CALL: 1-800-663-0399",
    "claim.submit.text": "PLEASE SUBMIT THIS FORM TO START A NEW CLAIM",
    "claim.describe": "Describe Your Situation",
    "claim.policy": "Policy #",
    "claim.upload.button": "UPLOAD IMAGE",
    "claim.add.button": "ADD AN IMAGE?",
    "claim.start.button": "START MY CLAIM",

    // ==============================Thanks page
    "thanks.title": "Thank you for your submission.",
    "thanks.text":
      "An iWareTravel representative will be in touch with you shortly to assist you !",
    "thanks.btn": "Return",

    // ==================================================comments
    "comment.1":
      "I bought travel insurance through Lancy for my mom and my sister’s family when they came to visit me in Calgary. Great service, very reliable and accountable. You wont be disappointed.",
    "comment.2":
      "The comprehensive travel insurance products and professional considerate services of iWare Travel allows me to enjoy my trip in Canada worry free. Thank you Lancy, and I look forward to doing business with you next time!",
    "comment.3":
      "I am a visiting teacher in the university, I bought 3 policies from Lancy for myself and my families. She is always patient in answering all my questions and recommending the best fit travel insurance plan for us. I trust her.",

    "comment.name.1": "Dzhu",
    "comment.name.2": "Elizabeth",
    "comment.name.3": "Al",
    "comment.name.4": "Lee",

    gender: "Gender",
    "gender.male": "Male",
    "gender.female": "Female",

    // ============================================================================about.page
    "about.text.head": "Message from the CEO and co-founder Lancy Qiu",
    "about.text.p1":
      "I am an immigrant in Canada, my parents come to visit me every year and stay for a few months.Let me tell you, SHIT HAPPENS.One year, my dad was hospitalized for 3 days in Canada which costed CAD13,000. Luckily I bought travel insurance for them, that’s the best investment I’ve ever made.",
    "about.text.p2":
      "For 21 million visitors to Canada each year, instead of buying travel insurance from their home countries, its better if they buy from Canada.",
    "about.tab.btn_1": "Why buy travel insurance from Canada?",
    "about.tab.btn_2": "Why buy travel insurance from us?",

    "about.tab.cont_1_1":
      "-You get direct billings between the Canadian insurance companies and the hospitals which means you don’t need to pay out of your own pocket",
    "about.tab.cont_1_2": "-You get optional pre-existing conditions coverage",
    "about.tab.cont_1_3":
      "-If you are already in Canada, you are not eligible to buy travel insurance from your home countries anymore. But don’t worry, Canada has your back.",

    "about.tab.cont_2_1":
      "Of course, you can spend hours searching and getting quote from different insurance companies, compare the coverages and type in their info every single time. Or you can use our platform to search, compare and buy travel insurance from Canada in one stop within minutes.",
    "about.tab.cont_2_2":
      " On top of this, check our blogs or talk to our online agent for more information related to your trip purposes in Canada, be it to visit your families, or to study, or to explore opportunities.",

    // ============================================================================insurer.page.title
    "insurer.page.title": "No insurance product fits all,",
    "insurer.page.subtitle": "let iWare Travel help you find your best fit.",
    "insurer.page.tugo":
      "TuGo specializes exclusively in insurance and assistance for travel’s ups and downs for over 55years. They offer a wide range of travel insurance products, including medical emergency and non-medical emergency (such as trip cancellation, trip interruption, baggage, rental car coverage, etc). Up to $300,000 maximum coverage is available. Tugo offers 2-person rate and family rate. ",
    "insurer.page.destination":
      "The Destination: Travel Group Inc. (DTGI) is a nationally licensed insurance organization that has been providing specialized travel insurance related products and services on a nationwide basis since the early 1990s. Up to $300,000 maximum coverage is available. Competitive rates for all ages, especially in the 70's. Covers stable (120 days) pre-existing medical conditions up to age 79 without the same restrictions as other plans",
    "insurer.page.gms":
      "Group Medical Services (GMS) was born in Saskatchewan in 1949, and they’ve been extendingtheir trademark combination of service, choice and value across Canada since 2003. Products not offered in Quebec, New Brunswick and Nunavut. Low rates especially for those travelers willing to use a higher deductible",
    //=================================================================errors msg
    "error.15days":
      "The infant traveller is ineligible for coverage, and cannot be added to the policy.",
    "error.18year":
      "To create a travel insurance policy, at least one traveller must be aged 18 or older. ",
    "error.addUser": "Please Add Traveler",
    "error.r_Error": "For non family members, please create a separate policy",
    "error.r_Error_empty": "Relationship can't be blank",
    "error.name": "First Name cannot be blank",
    "error.lname": "Last Name cannot be blank",
    "error.bday": "Please Enter Birth Date",

    // ===================================================================viewMyPolicy

    "view.policy.sub": "Quick, Easy, Access.",
    "view.policy.formTitle": "TO ACCESS YOUR POLICY ENTER ONE OF THE FOLLOWING",
    "view.policy.formPlaceholder": "Email, Phone Number or Policy# ",

    "view.privacy": "VIEW PRIVACY POLICY",

    "tugo.declaration.title": "DECLARATION",
    "tugo.declaration": `
    The traveller confirms that they know of no reason for which they may seek medical attention.
    <br>
    The traveller confirms that they have not seen a physician or other registered medical practitioner since their arrival in Canada
    <br>
    The traveller confirms that they have not submitted a claim and no circumstance is known for which a claim may be made.
    <br>
    The traveller confirms that if this policy is purchased within 60 days after their arrival in Canada, there is no coverage for any sickness arising in, occurring sin or symptomatic during the first 48 hours from the effective date of this policy.
    <br>
    The traveller understands that if this policy is purchase more than 60 days after their arrival in Canada, there is no coverage for any sickness arising in, occurring in or symptomatic during the first 7 days from the effective date of this policy.`,
    "tugo.eligibility.title": "ELIGIBILITY",
    "tugo.eligibility": `
      Are you eligible for coverage?
      <br>
      At the time of application, you are eligible for coverage if:
      <br>
      1. You are not travelling against a physician's advice.
      <br>
      2. You have not been diagnosed with a terminal condition.
      <br>
      3. You are not receiving palliative care or palliative care has not been recommended.
    `,

    "policy.wording.text":
      "This is a summary of benefits only. For details please refer to ",
    "policy.wording.link": "policy wording document",
  },

  "ch-MA": {
    "policy.wording.text": "以上只是该保险计划的保障摘要, ",
    "policy.wording.link": "具体信息请阅读保单",
    "tugo.declaration.title": "DECLARATION",
    "tugo.declaration": `被保险 确认他们没有任何原因寻求医疗服务。被保险 确认 抵达加拿 开始，他们没有 看过医 或寻求其他医疗服务。被保险 确认他们没有申请过保险理赔或有任何需要理赔的情况。被保险 理解如果该保险计划是在抵达加拿 60天以内购买的， 保险 效 起48 时内出现症状的急病是没有保障的。被保险 理解如果该保险计划是在抵达加拿 60天以后购买的， 保险 效 起7天内出现症状的急病是没有保障的。`,
    "view.privacy": "view.privacy CH",

    "error.r_Error": "非家庭成员建议分别购买保险",
    "error.r_Error_empty": "Relationship can't be blank",

    // ============================NAVIGATION
    "nav.home": "询个价",
    "nav.contacts": "联系我们",
    "nav.license": "iWare Travel的保险执照是通过以下公司发起并保证的",
    "nav.policy": "查看我的保单",
    "nav.claim": "保险理赔",
    "nav.insurer": "保险公司",
    "nav.about": "关于我们",

    // =========================stepNavigation
    "step.move.prev": "上一步",
    "step.move.next": "下一步",

    // =========================contact us link
    "contUsLink.title": "需要帮忙？",
    "contUsLink.subTitle": "联系我们！",

    // =========================firstStep
    "step.first.video": "通过⼩视频了解更多.",
    "step.first.title": "旅游保险，",
    "step.first.subTitle": "让你的旅途后顾无忧",

    "step.first.linkTitle": "60秒内获得报价!",
    "step.first.linkSubTitle": " ",

    "about.title_2": "了解我们",

    "step.first.insuresLinkTitle": "为什么从加拿大买旅游保险？",
    "step.first.insuresLinkList_f":
      "直赔功能-住院不用自掏腰包，保险公司直接买单",
    "step.first.insuresLinkList_s":
      "人已经在加拿大无法再从国内买保险？没问题，我们帮你从加拿大买",
    "step.first.insuresLinkList_t":
      "既往病史（如慢性病）一旦发作不可小觑，你确定你的保险包含这部分吗？",

    // =========================twoStep===
    "step.two.title": "来加拿大干点啥",
    "step.two.treveler_1": "旅游探险",
    "step.two.treveler_2": "探亲访友",
    "step.two.treveler_3": "留学夏令营",
    "step.two.treveler_4": "寻找机会",

    // ===============================threeStep===

    "step.three.title": "旅行计划",
    "step.three.from": "从哪来",
    "step.three.to": "到哪去",
    "step.three.departingDate": "起始日",
    "step.three.returningDate": "终止日",
    "step.three.selectCountry": "选择国家",
    "step.three.chooseProvince": "选择省份",

    "step.three.policyNumber": "保单号码",

    // =========================fourStep===
    "step.four.title": "人在旅途",
    "step.four.travelersName": "旅行者姓名",
    "step.four.travelersBirthDate": "生日",
    "step.four.FName": "名",
    "step.four.LName": "姓",
    "step.four.month": "月",
    "step.four.day": "日",
    "step.four.year": "年",
    "step.four.add": "添加",

    "step.four.rStatus": "關係狀態",
    "step.three.selectStatus": "關係狀態",

    // ==========================fiveStep

    "step.five.title": "這是您的報價！",
    "step.five.string": "選擇您的承保範圍選項，然後選擇一家保險公司",
    "step.five.btn": "我選擇此政策",

    "step.five.Deductible": "垫底费",
    "step.five.LimitOptions": "保障额度",
    "step.five.MedicalCoverage": "利益摘要",
    "step.five.MedicalCoverageCovid": "没有新冠状病毒保障",
    "step.five.OptionalMedical": "可选的医疗保障",
    "step.five.OptionalCoverage": "可选的保障",

    "step.five.EmergencyMedical": "紧急医疗保障",
    "step.five.EmergencyMedicalC": "紧急医疗保障",
    "step.five.PreExisting": "既往病史",
    "step.five.AccidentalDeth": "意外死亡和残疾",
    "step.five.VisitorsCanada": "加拿大游客医疗保障",

    "step.five.PreExistingCoverage": "既往病史保障",
    "step.five.nonMedicalCoverage": "非医疗保障",
    "step.five.RentalCarProtection": "租车保障",
    "step.five.TripCTI": "行程取消以及行程中断",
    "step.five.TripInterruption": "行程中断",
    "step.five.SportsCoverage": "运动保障",
    "step.five.ContactSports": "接触性运动 – 18+",
    "step.five.AdventureSports": "探险运动-所有年龄",
    "step.five.ExtremeSport": "极限运动-所有年龄",
    "step.five.Baggae": "行李",

    "step.five.Baggage": "行李",
    "step.five.RentalCar": "租车",
    "step.five.Sports": "运动",

    "step.four.travelersName_f": "名",
    "step.four.travelersName_l": "姓",

    cost: "成本",

    "step.five.benefits": "保障",
    "step.five.details": "具体信息",
    "step.five.gms.Hospitalization": "住院",
    "step.five.gms.Hospitalization_c":
      "覆盖医院病房费用（半私人病房），除非医生有特别要求（如果必要，可以覆盖重病特别护理病房或冠心病监测病房）",
    "step.five.gms.MedicalServices": "医疗服务",
    "step.five.gms.MedicalServices_c": "覆盖医生治疗费用",
    "step.five.gms.Diagnostic": "诊断服务",
    "step.five.gms.Diagnostic_c": "50,000保障额度",
    "step.five.gms.n_hospita": "住院药物治疗",
    "step.five.gms.n_hospita_c": "覆盖处方药和药物治疗（最多30天）",
    "step.five.gms.Road": "救护车",
    "step.five.gms.Road_c": "覆盖100%紧急情况的救护车费用",
    "step.five.gms.Paramedical": "医疗辅助服务",
    "step.five.gms.Paramedical_c":
      "覆盖最多300加币的紧急情况下的整骨疗法、物理疗法、脊椎按摩、手足科以及足疗。",
    "step.five.gms.Accidental": "紧急牙医",
    "step.five.gms.Accidental_c": "覆盖每人最多2000加币，250加币的牙齿止痛",
    "step.five.gms.Return": " 遗体运回",
    "step.five.gms.Return_c": " 覆盖将遗体运回母国的费用",

    "step.five.dest.Maximum": "最大保障范围",
    "step.five.dest.EmergencyHosp": "紧急住院",
    "step.five.dest.Semi": "半私人病房",
    "step.five.dest.EmergencyMed": "紧急药物治疗",
    "step.five.dest.Services": "医生和麻醉服务",
    "step.five.dest.Services_1": "医生和麻醉服务",
    "step.five.dest.Services_2": "诊断、检测和/或X光",
    "step.five.dest.Services_3":
      "运送到最近的医院的空运、陆运或海运的救护车费用",
    "step.five.dest.Services_4": "最高10000加币的注册护士护理费用",
    "step.five.dest.Services_5": "租用医疗器械的费用",
    "step.five.dest.Drugs": "药物或药物治疗",
    "step.five.dest.UpTo": "最高1000加币不超过30天的药量",
    "step.five.dest.ProfessionalServ": "专业服务",
    "step.five.dest.Physiotherapist":
      "物理疗法、脊椎按摩、手足科、整骨疗法以及足科，每项疗法不超过500加币门诊治疗",
    "step.five.dest.EmergencyAir": "紧急空运/运返回家",
    "step.five.dest.WhenPre": "必须提前取得active care management公司的审批",
    "step.five.dest.Transportation": "家庭成员的运送",
    "step.five.dest.ToTran":
      "最高达3000加币的运送一名家庭成员或亲友的费用以及不超过1000加币的住宿和餐饮费用",
    "step.five.dest.PreExisting": "既往病史",
    "step.five.dest.Age": "0-79岁",
    "step.five.dest.Option_1":
      "选择1：只覆盖保险生效日之前的120天内稳定的既往病史",
    "step.five.dest.Option_2":
      "选择2：80岁以及以上的人群，不承保既往病史-所有的既往病史都属于除外责任",

    "step.five.dest.Limitations": "免责和限制条款",
    "step.five.dest.DeductOp": "垫底费选择",
    "step.five.dest.YourPolicy":
      "你的保单包括某些免责和限制条款。请仔细阅读保单。",
    "step.five.dest.AgeUp": `85岁及以下/垫底费可为零（有其他垫底费可选择）
    86岁及以上/垫底费最低可选500加币`,

    gender: "Gender",
    "gender.male": "Male",
    "gender.female": "Female",

    "step.five.dest.can": "Destination: Canada - Eligibility: Requirements",
    "step.five.dest.can1": "Coverage is NOT AVAILABLE to any individual who:",
    "step.five.dest.can2": "has been diagnosed with a terminal illness;",
    "step.five.dest.can3":
      "has Acquired Immune Deficiency Syndrome (AIDS) or Human Im",
    "step.five.dest.can4": "munodeficiency Virus (HIV);",
    "step.five.dest.can5":
      "has been prescribed or used home oxygen treatment in the last 12 months;",
    "step.five.dest.can6":
      "has been diagnosed with or treated for congestive heart failure;",
    "step.five.dest.can7":
      "has had a major organ transplant (heart, kidney, liver, lung);",
    "step.five.dest.can8":
      "has received kidney dialysis treatment in the last 12 months.",
    "step.five.dest.can9": "To be eligible for coverage you must:",
    "step.five.dest.can10": "be at least 15 days old;",
    "step.five.dest.can11":
      "on the effective date, not be insured or eligible for benefits under a Canadian government health insurance plan;",
    "step.five.dest.can12":
      "be in good health at the time you purchase your policy and on the  effective date, and know of no reason why you would attend any  medical consultation during the period of coverage.",

    "step.five.dest.WaitingPeriod": "等待期",
    "step.five.dest.Dest_1": "85岁以下",
    "step.five.dest.Dest_2": "如果在抵达加拿大以前购买，则没有等待期",
    "step.five.dest.Dest_3":
      "如果在抵达加拿大以后购买，有48小时的等待期，也就是说从保险生效日开始的48小时内出现的急病是没有保障的",
    "step.five.dest.Dest_4": "86岁及以上",
    "step.five.dest.Dest_5": "如果在抵达加拿大以前购买，则没有等待期",
    "step.five.dest.Dest_6":
      "如果在抵达加拿大以后购买，则有15天的等待期，也就是说从保险生效日开始的15天内出现的急病是没有保障的",
    "step.five.dest.Dest_7": "在以下情况下，等待期是可以免除的：",
    "step.five.dest.Dest_8":
      "如果在之前购买的本公司保险产品到期之前购买新的保险产品",
    "step.five.dest.Dest_9":
      "如果之前购买了其他保险公司的产品，并且在抵达加拿大后购买本保险产品，同时两个产品的保障期间没有断档。必须提供另一家保险公司保障的书面证明。",

    "step.five.tugo.medicalCoverage_1": "紧急医疗保险",
    "step.five.tugo.medicalCoverage_2":
      "出门在外尤其是人在国外，你需要一份紧急医疗保险以应对突发急病或意外。有了这份保险可以让你的旅行没有后顾之忧，从住院，到紧急牙医，到送返陪同宠物，在你最需要的时候派上用场。",
    "step.five.tugo.medicalCoverage_3":
      "适用于所有年龄人群（假期旅行套餐除外）",
    "step.five.tugo.medicalCoverage_4":
      "住院津贴和治疗包括：医生问诊 救护车 胸透和其他诊断检查 处方药 必要的医疗物品如拐杖",
    "step.five.tugo.medicalCoverage_5": "骨折治疗",
    "step.five.tugo.medicalCoverage_6": "其他医疗服务，如理疗",
    "step.five.tugo.medicalCoverage_7": "紧急牙科服务",
    "step.five.tugo.medicalCoverage_8": "附加服务如电视租赁",
    "step.five.tugo.medicalCoverage_9": "紧急空运（必须提前批复）",
    "step.five.tugo.medicalCoverage_10": "送返多余行李",
    "step.five.tugo.medicalCoverage_11": "送返同行伴侣",
    "step.five.tugo.medicalCoverage_12": "送返随行子女/孙子女",
    "step.five.tugo.medicalCoverage_13": "家庭转运",
    "step.five.tugo.medicalCoverage_14": "实报实销费用如住宿和餐饮",
    "step.five.tugo.medicalCoverage_15": "把车辆送返至出发国或最近的租赁场所",
    "step.five.tugo.medicalCoverage_16":
      " 如果您需要空運到家鄉去看護，請返回目的地",
    "step.five.tugo.medicalCoverage_17": "送返随行宠物",

    "step.five.tugo.luggage_1": "行李险",
    "step.five.tugo.luggage_2": "可作为单次行程的附加保障",
    "step.five.tugo.luggage_3":
      "对于丢失、损坏或被窃的行李和个人用品的补偿，不论在世界的任何角落。",
    "step.five.tugo.luggage_4": "按照不超过本项保障限额补偿被窃现金",
    "step.five.tugo.luggage_5":
      "如果你的行李被延误，本计划将按照不超过保障限额支付个人必需品",

    "step.five.tugo.rental_1": "租车险",
    "step.five.tugo.rental_2": "可作为单次行程的附加保障",
    "step.five.tugo.rental_3": "租赁车辆有形损坏",
    "step.five.tugo.rental_4": "租赁车辆损失",
    "step.five.tugo.rental_5":
      "拖车、救助、消防、海关费用以及合理的使用损失所产生的费用",

    "step.five.tugo.sport_1": "可选的运动保障",
    "step.five.tugo.sport_2":
      "极限运动爱好者需要的保障！不论你是徒步、高空索道、蹦极、甚至滑翔伞，都可以尽情享受。",
    "step.five.tugo.sport_3": " ",

    "step.five.tugo.sport_4": "接触性运动保障",
    "step.five.tugo.sport_5":
      "喜欢挑战？年满18岁的冒险爱好者需要这份保障。如果不满18岁，无需单独购买此份保障，你的紧急医疗保障里面已经包含了！",
    "step.five.tugo.sport_6": "运动项目",
    "step.five.tugo.sport_7": "包括吗？",
    "step.five.tugo.sport_8": "澳大利亚足球",
    "step.five.tugo.sport_9": "拳击",
    "step.five.tugo.sport_10": "F橄榄球",
    "step.five.tugo.sport_11": "冰球",
    "step.five.tugo.sport_12": "曲棍球",
    "step.five.tugo.sport_13": "英式橄榄球",

    "step.five.tugo.sport_14": "探险运动保障 ",
    "step.five.tugo.sport_15": "本保障适合那些喜爱户外探险运动的朋友 ",
    "step.five.tugo.sport_16": "野外双板滑雪",
    "step.five.tugo.sport_17": "野外单板滑雪",
    "step.five.tugo.sport_18": "野外雪鞋徒步",
    "step.five.tugo.sport_19": "雪橇车",
    "step.five.tugo.sport_20": "峡谷漂流",
    "step.five.tugo.sport_21": "下坡自由式滑雪",
    "step.five.tugo.sport_22": "下坡滑雪比赛",
    "step.five.tugo.sport_23": "冲浪",
    "step.five.tugo.sport_24": "山地骑行",
    "step.five.tugo.sport_25": "滑冰",
    "step.five.tugo.sport_26": "超过六小时的耐力运动",
    "step.five.tugo.sport_27": "高达6000米的登山运动",
    "step.five.tugo.sport_28": "滑翔机或超轻型飞机运动",
    "step.five.tugo.sport_29": "悬挂式滑翔运动",
    "step.five.tugo.sport_30": "高风险雪地摩托",
    "step.five.tugo.sport_31": "攀冰运动",
    "step.five.tugo.sport_32": "雪橇",
    "step.five.tugo.sport_33": "非机动游戏运动",
    "step.five.tugo.sport_34": "降落伞/跳伞/双人跳伞",
    "step.five.tugo.sport_35": "滑翔伞/跳伞 ",
    "step.five.tugo.sport_36": "动力伞",
    "step.five.tugo.sport_37": "雪上滑伞",
    "step.five.tugo.sport_38": "特技飞行",

    "step.five.tugo.sport_39": "极限运动保障 ",
    "step.five.tugo.sport_40": "此份保障适合极限运动爱好者",
    "step.five.tugo.sport_41": "水肺潜水 （没有经过国际认证的）",
    "step.five.tugo.sport_42": "超过30米的水肺潜水或自由式潜水",
    "step.five.tugo.sport_43": "定点跳伞",
    "step.five.tugo.sport_44": "骑牛或斗牛运动",
    "step.five.tugo.sport_45": "牛仔竞技",
    "step.five.tugo.sport_46": "和公牛赛跑",
    "step.five.tugo.sport_47": "机动游戏运动",
    "step.five.tugo.sport_48": "超过6000米的登山运动",
    "step.five.tugo.sport_49": "终极格斗和武术",
    "step.five.tugo.sport_50": "翼服飞行",

    "step.five.tugo.sport_51": "除外运动",
    "step.five.tugo.sport_52":
      "无论你从事哪项运动，确保它是在保障范围内的。如果不在上述接触性运动、探险运动或极限运动里面，也有可能是在医疗保障里已经包括了，除非是明确列为除外运动。",
    "step.five.tugo.sport_53": "越界滑雪、单板、雪鞋或非机动雪地摩托",
    "step.five.tugo.sport_54":
      "任何在可选运动保障里面列示的运动，除非你已经购买了相应的保障",

    // ==========================================stepSix
    "step.six.title": "查看您的政策。",
    "step.six.subtitle": "滿意後，購買您的保單！",
    "step.six.tab_btn_1": "旅行計劃",
    "step.six.tab_btn_2": " 被保险人",
    "step.six.tab_btn_3": "保障范围",
    "step.six.trip_length": "行程長度",
    "step.six.days": "天",
    "step.six.deductible": "免賠額",
    "step.six.coverage": "保障范围",

    // ==========================contactDetailStep===

    "step.contactDetail.title": "马上就好了！",
    "step.contactDetail.string": "报价发到哪里呢？",
    "step.contactDetail.email": "邮箱",
    "step.contactDetail.phone": "电话",
    "step.contactDetail.phoneor": "电话或微信",
    "step.contactDetail.btn": "获取报价",

    // ==========================paymentStep

    "payment.title": "购买保险",
    "payment.subtitle": "立即购买，最终确定您的政策。",
    "payment.full_address": "完整地址（加拿大)",
    "payment.card_num": "信用卡号",
    "payment.card_date": "到期日",
    "payment.btn": "购买保险",
    "payment.processing": "处理中",

    //=============================compleateStep===

    "step.complite.title": "恭喜!您的保单已经完成!",
    "step.complite.string": "您的保单已经发到邮箱里了!",
    "step.complite.btn": "返回",

    // ==============================firstPage Screen2===

    "fp.screenTwo.title": "为什么要买旅游保险？",

    "fp.screenTwo.titleTab1": "万一既往病史（如慢性病）发作需要紧急就医",
    "fp.screenTwo.titleTab2":
      "人已经在境外，不能再从国内买保险了？没关系，我们帮你搞定",
    "fp.screenTwo.titleTab3": "行李丢失",
    "fp.screenTwo.titleTab4": "航班延误",
    "fp.screenTwo.titleTab5": "行程取消",

    "fp.screenTwo.fearFormTitle": "旅行中最让你担心的是什么？",
    "fp.screenTwo.submit": "提交",
    "fp.screenTwo.selectAnsver": "请选择",

    "fp.screenTwo.fear5": "忘记带平时定期服用的药",
    "fp.screenTwo.fear3": "错过航班或丢失行李",
    "fp.screenTwo.fear1": "发生紧急情况的时候找不到帮助",
    "fp.screenTwo.fear4": "需要自掏腰包支付巨额医药费",
    "fp.screenTwo.fear2": "已经开始您的旅行了吗？您仍然可以获得旅行保险！",

    "fp.screenTwo.fear_info_text1":
      "担心既往病史（如心脏病和高血压等慢性病）在旅途中发作吗？既往病史保障可以让你后顾无忧。有些公司像Manulife和Destination Travel提供两种选择，有既往病史保障和没有既往病史保障。有些公司如GMS只提供带既往病史保障的计划。我们的在线购买流程会提示你。",
    "fp.screenTwo.fear_info_text2":
      "已经开始旅程而不具备从母国购买旅游保险的资格了？别担心，有我们呢。你可以在任何时间（出发前以及出发后）在我们的平台上购买旅游保险。温馨提示，出发后购买可能会有等待期。",
    "fp.screenTwo.fear_info_text3":
      "我们理解在旅途中行李丢失特别是贵重物品丢失的沮丧和烦恼。不用担心，某些公司的产品（如Tugo）提供行李丢失的保障。",
    "fp.screenTwo.fear_info_text4":
      "担心被滞留某地或错过转机航班？你需要行程中断保障。温馨提示：该保障只报销不可返还的旅行费用。",
    "fp.screenTwo.fear_info_text5":
      "因为工作或急病不得不取消行程？行程取消保障将弥补你预付的、不可返还以及不可恢复的旅行费用。",

    // ==============================firstPage Screen3===
    "fp.screenThree.title": "我们的客户怎么说",
    "fp.screenThree.subtitle": " ",

    // ==============================firstPage Screen4
    "fp.screenFour.title": "关于我们",
    "fp.screenFour.btn": "免费报价",

    "fp.screenFour.bold":
      "作为一个加拿大移民,我父母每年都会来加拿大看我住上几个月。那一年,父亲因为急病住了三天医院花 费13000加币。万幸的是我买了旅游保险。这恐怕是我做的最好的投资之一了。",
    "fp.screenFour.norm":
      "你知道吗?旅游保险既可以从出发地购买也可以从目的国购买?我的经验是从目的国购买更好一些。拿加 拿大来说,游客如果从加拿大购买旅游保险可以获得更好的服务,比如直赔服务(保险公司和医院直接结 算,不用自掏腰包垫付,省却理赔的烦恼),或者更全面的保障(比如既往病史的保障)",

    // ==============================contact us page

    "contactUs.title": "联系我们？",
    "contactUs.subTitle": "来对地方了",
    "contactUs.formTitle": "提交该表格并与我们取得联系",
    "contactUs.fn": "名",
    "contactUs.ln": "姓",
    "contactUs.email": "邮箱",
    "contactUs.message": "想说点啥？",
    "contactUs.send": "发送信息",

    // ==============================claim page

    "claim.title": "有紧急情况发生怎么办?",
    "claim.subtitle": " ",
    "claim.text":
      "首先,你需要在可能的最快时间内联系保险公司。所需信息包括你的保单号以及保险公司联系方 式都可以在钱包卡(wallet card)上面找到",
    "claim.call": "Tugo联系电话: 1-800-663-0399",
    "claim.submit.text": "请填写此表格开始保险索赔",
    "claim.describe": "请描述一下具体情况",
    "claim.policy": "政策 #",
    "claim.upload.button": "上传照片",
    "claim.add.button": "添加照片",
    "claim.start.button": "开始索赔",

    // ==============================Thanks page
    "thanks.title": "感謝您提交。",
    "thanks.text": "iWareTravel代表將盡快與您聯繫，以協助您提出索賠",
    "thanks.btn": "返回",

    // ==================================================comments
    "comment.1":
      "妈妈和姐姐一家来加拿大都在Lancy这里买的保险。服务好，人品好，信誉有保证。不会让大家失望的。",
    "comment.2":
      "iWare Travel以其专业全面的产品种类，贴心细致的顾问服务，让人徜徉在加拿大的极致景致中可以尽兴无忧。感谢之余，期待下次旅行再相会。",
    "comment.3":
      " 我是卡尔加里大学的访学老师，从iWare Travel为我和家人前后买了三份旅游保险。他们总是非常耐心地回答我的问题并推荐最适合我们的产品。",

    "comment.name.1": "Dzhu 说",
    "comment.name.2": "Elizabeth说",
    "comment.name.3": "Al说",
    "comment.name.4": "Lee说",

    // ============================================================================about.page
    "about.text.head": "Message from the CEO and co-founder Lancy Qiu",
    "about.text.p1":
      "我是加拿大移民，父母每年都会来加拿大住上几个月共享天伦。天有不测风云，人有旦夕祸福。那一年，父亲在加拿大急诊住院，三天花费了13,000加币。幸好我给他们买了旅游保险，那是我迄今为止做的最好的投资之一。",
    "about.text.p2":
      "对于每年来加拿大的2100万游客来说，从加拿大购买旅游保险是更好的选择。",
    "about.tab.btn_1": "为什么要从加拿大购买旅游保险呢？",
    "about.tab.btn_2": "为什么从我们iWare Travel购买旅游保险？",

    "about.tab.cont_1_1":
      "-加拿大的保险公司和当地的医疗机构都有直付协议，也就是说，",
    "about.tab.cont_1_2":
      "一旦需要就医，你不用自己掏腰包垫付（这可不是小钱）-你还可以选择既往病史的保障（这对于来探亲的父母、祖父母可是非常重要的，人上了年纪，难免会有这样或那样的慢性病，如果没有这个保障，",
    "about.tab.cont_1_3":
      "一旦出现紧急情况需要就医，巨额医药费就要自己负担了。中国的旅游险产品往往都是不保既往病史的。）-如果你已经在加拿大境内，就不符合从母国购买旅游保险的条件了。但不用担心，有我们呢",

    "about.tab.cont_2_1":
      "如果你愿意花上几小时去搜索不同的保险公司，比较它们的价格和保障，并且每一家公司都要重复输入自己的信息以获取报价，这当然没有任何问题。或者，你可以使用我们的平台，只需输入一次信息，就可以获取多家加拿大保险公司的报价和比较，一切都可以在几分钟内完成。",
    "about.tab.cont_2_2":
      "我们的服务不止于此，通过我们的博客（微信公众号）和客服，我们可以为你的旅程提供更多信息，无论你是来加拿大探亲、留学还是寻找新的机会。",

    // ============================================================================insurer.page.title
    "insurer.page.title": "没有任何保险产品是适合所有人的，",
    "insurer.page.subtitle": "让iWare Travel帮助你找到最适合你的旅游保险产品。",
    "insurer.page.tugo":
      "Tugo专注于旅游保险超过55 年。他们的旅游保险产品覆盖范围很广，包括紧急医疗保险和非医疗保险（例如，行程取消，行程中断，行李丢失，租车险等等）。最高保障额度高达30万加币，同时还提供双人费率和家庭费率。",
    "insurer.page.destination":
      "DTGI从九十年代初开始提供旅游保险相关产品，目前已发展到一家全国性的保险机构。最高保障额度高达30万加币，其费率非常有竞争力，尤其是针对70-80年龄段的人群。其既往病史的保障（稳定期120天）可以覆盖到79岁且不像其他保险产品限制条件那么多。",
    "insurer.page.gms":
      "GMS1949年诞生于萨省。其保险范围不覆盖魁北克省，新不伦瑞克省以及努勒维特省。该公司鼓励利用垫底费（自己负担的部分）来降低费率。",

    //=================================================================errors msg
    "error.15days": "15天以下的新生儿不符合获保条件。",
    "error.18year": "购买旅游保险,至少需要有一名旅行者是满18岁的。",
    "error.addUser": "添加被保险人",
    "error.name": "名字不能是空白",
    "error.lname": "姓不能是空白",
    "error.bday": "请输入出生日期",

    // ===================================================================viewMyPolicy

    "step.five.destination.nonMedicalTitle":
      "Pre-existing medical condition means a sickness, injury or medical condition, whether or not diagnosed by a physician",
    "step.five.destination.nonMedicalItems": `
           - for which you exhibited signs or symptoms; or
           - for which you required or received any medical consultation; and
           - which existed prior to the effective date of your coverage.`,

    "view.policy.sub": "快速，轻松，访问。",
    "view.policy.formTitle": "请输⼊以下信息中的任何⼀项就可以查看你的保单",
    "view.policy.formPlaceholder": "电⼦邮箱，电话，保单号",
  },
};
