import axios from "axios";
import { API_URL } from "../constants/utils";

const countries = `${API_URL}countries`;
const agecheck = `${API_URL}ages`;
const conditions = `${API_URL}conditions`;

// const lead = `${API_URL}lead`;
const health = `${API_URL}health`;
const token = `${API_URL}token`;

const insurers = `${API_URL}insurers`;

const quote_gms = `${API_URL}quote/gms`;
const quote_destination = `${API_URL}quote/destination`;
const quote_travelance = `${API_URL}quote/travelance`;
const quote_tugo = `${API_URL}quote/tugo`;

const purchase_gms = `${API_URL}purchase/gms`;
const purchase_destination = `${API_URL}purchase/destination`;
const purchase_travelance = `${API_URL}purchase/travelance`;
const purchase_tugo = `${API_URL}purchase/tugo`;

const search = `${API_URL}search`;

export const getCountries = () => axios.get(countries);

export const checkAge = travelers => axios.post(agecheck, travelers);

export const getConditions = travelers => axios.post(conditions, travelers);

// ==========================================================START LEAD
// export const setLead = data => axios.post(lead, data);
export const getToken = () =>
  axios.get(token).then(res => {
    // need to send through access_token for every request
    axios.interceptors.request.use(
      config => {
        config.headers["Authorization"] = "Bearer " + res.data.access_token;

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    return res;
  });
// ==========================================================END LEAD

// ==========================================================START Health
export const getHealth = () => axios.get(health);
// ==========================================================END Health

// ==========================================================START GETINSURER
export const getInsurers = () => axios.get(insurers);
// ==========================================================END GETINSURER

// ==========================================================START QUOTE
export const setQuoteGms = quote => axios.post(quote_gms, quote);

export const setQuoteDestination = quote =>
  axios.post(quote_destination, quote);

  export const setQuoteTravelance = quote =>
  axios.post(quote_travelance, quote);

export const setQuoteTugo = quote => axios.post(quote_tugo, quote);
// ==========================================================END QUOTE

// ==========================================================START PURCHASE
export const purchaseGms = data => axios.post(purchase_gms, data);

export const purchaseDestination = data =>
  axios.post(purchase_destination, data);

  export const purchaseTravelance = data =>
  axios.post(purchase_travelance, data);
export const purchaseTugo = data => axios.post(purchase_tugo, data);
// ==========================================================END PURCHASE

export const searchPolicy = (data, type) =>
  axios.get(`${search}?${type}=${data}`);

// export const searchPolicy1 = data =>
//   axios.get(search, "john@gms.ca").then(res => console.log(res));
// searchPolicy1();
