import React, { useCallback } from "react";
import { connect } from "react-redux";
import styles from "./burger.module.scss";

import { toggleNav } from "../../redux/actions/nav";

const Burger = ({ toggleNav, nav, ...props }) => {
  const togNav = useCallback(() => {
    nav ? toggleNav(false) : toggleNav(true);
  }, [toggleNav, nav]);

  return (
    <div className={styles.burgerCont} onClick={togNav}>
      <div
        className={nav ? `${styles.burger} ${styles.active}` : styles.burger}
      >
        <span className={styles.burger__top}></span>
        <span className={styles.burger__middle}></span>
        <span className={styles.burger__bottom}></span>
      </div>
    </div>
  );
};

const mapStateToProps = ({ nav }) => ({
  nav
});
const mapDispatchToProps = {
  toggleNav
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Burger);
