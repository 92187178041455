import ACTION from "../../constants/constants";
import { isDev } from "../../lib/dev";
import { Math as _Math } from 'three'

export const R_STATUSES = [
  'Policy Holder', 
  'Spouse',
  'Dependant',
  'Other'
]

const initState = {
  travelers: isDev ?
    [
      {
        fname: 'dev',
        lname: 'dev',
        id: 'someid_id',
        r_status: 'Policy Holder',
        dateMonth: "06",
        dateDay: "06",
        dateYear: "1975",
    }
    ]
    :
    []
};
export default (state = initState, action) => {
  switch (action.type) {
    case ACTION.ADD_TREVELERS:
      return {
        travelers: [
          ...state.travelers,
          {
            ...action.traveler,
            id: _Math.generateUUID() + "_id"
          }
        ]
      };
    case ACTION.REMOVE_TREVELERS:
      return {
        travelers: state.travelers.filter(t => t.id !== action.travelerId)
      };
    case ACTION.CLEAR_TREVELERS:
      return {
        ...initState
      };
    default:
      return state;
  }
};
