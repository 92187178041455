import { combineReducers } from "redux";
import step from "./stepReducer";
import purpose from "./purposeReducer";
import traveler from "./travelerReducer";
import apiStatus from "./apiStatusReducer";
import fears from "./fearsReducer";
import nav from "./navReducer";
import fp from "./fpApiReducer";
import travelPlan from "./travelPlanReducer";
import myPolicy from "./myPolicyLoadReducer";
import sceneApi from "./sceneApiReducer";
import { blockStep, blockStepTrigger } from "./blockStepReducer";
import blockFields from "./blockFieldsTravelPlan";
import modalTemp, { medicalPopupCompleted, tugoEligible } from "./modalReducer";
import {
  gmsPolicy,
  tugoPolicy,
  destinationPolicy,
  travelancePolicy,
  policyObj,
  GMS_remove,
  Travelance_remove,
} from "./policyReducer";
import {
  resTravelesAge,
  resConditions,
  countries,
  resQuoteGMS,
  resQuoteDestination,
  resQuoteTravelance,
  resQuoteTugo,
  resPurchaseGMS,
  resPurchaseDestination,
  resPurchaseTravelance,
  resPurchaseTugo,
  resLead,
  resHealth,
  resInsurers,
  resPolicySearch,
} from "./apiReducer";

export default combineReducers({
  step,
  purpose,
  traveler,
  countries,
  apiStatus,
  fears,
  nav,
  fp,
  travelPlan,
  sceneApi,
  blockStep,
  blockFields,
  blockStepTrigger,
  modalTemp,
  myPolicy,
  gmsPolicy,
  tugoPolicy,
  destinationPolicy,
  travelancePolicy,
  resTravelesAge,
  resConditions,
  resQuoteGMS,
  resQuoteDestination,
  resQuoteTravelance,
  resQuoteTugo,
  resPurchaseGMS,
  resPurchaseDestination,
  resPurchaseTravelance,
  resPurchaseTugo,
  resLead,
  resHealth,
  resInsurers,
  policyObj,
  resPolicySearch,
  GMS_remove,
  Travelance_remove,
  medicalPopupCompleted,
  tugoEligible,
});
