import ACTION from "../../constants/constants";
import * as api from "../../api/api";
import { beginApiCall, apiCallError, apiCallEnd } from "./apiStatusActions";

export const loadCountries = () => dispatch => {
  dispatch(beginApiCall());
  return api
    .getCountries()
    .then(countries => {

        return dispatch({
          type: ACTION.LOAD_COUNTRY_SUCCESS,
          payload: countries.data
            .sort((a, b) => a.name.toLowerCase() === "china" ? -1 : +1)
        });
      }
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};

export const checkTravelersAge = travelers => dispatch => {
  dispatch(beginApiCall());
  return api
    .checkAge(travelers)
    .then(res =>
      dispatch({
        type: ACTION.CHECK_TRAVELERS_AGE,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};

export const getConditions = travelers => dispatch => {
  dispatch(beginApiCall());
  return api
    .getConditions(travelers)
    .then(res =>
      dispatch({
        type: ACTION.GET_CONDITIONS,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
// ============================================================START QUOTE
export const setQuoteGms = quote => dispatch => {
  dispatch(beginApiCall());
  return api
    .setQuoteGms(quote)
    .then(res =>
      dispatch({
        type: ACTION.SET_QUOTE_GMS,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};

export const setQuoteDestination = quote => dispatch => {
  dispatch(beginApiCall());
  return api
    .setQuoteDestination(quote)
    .then(res =>
      dispatch({
        type: ACTION.SET_QUOTE_DESTINATION,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};

export const setQuoteTravelance = quote => dispatch => {
  dispatch(beginApiCall());
  return api
    .setQuoteTravelance(quote)
    .then(res =>
      dispatch({
        type: ACTION.SET_QUOTE_TRAVELANCE,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};

export const setQuoteTugo = quote => dispatch => {
  dispatch(beginApiCall());
  return api
    .setQuoteTugo(quote)
    .then(res =>
      dispatch({
        type: ACTION.SET_QUOTE_TUGO,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
// ============================================================START QUOTE

// ============================================================START PURCHASE
export const purchaseGms = data => dispatch => {
  dispatch(beginApiCall());

  return api
    .purchaseGms(data)
    .then(res => {
      dispatch({
        type: ACTION.PURCHASE_GMS,
        payload: res
      });
    })
    .then(() => dispatch(apiCallEnd()))
    .catch(err => {
      dispatch(apiCallError(err.response.data.error));
      throw err;
    });
};

export const purchaseDestination = data => dispatch => {
  dispatch(beginApiCall());
  return api
    .purchaseDestination(data)
    .then(res =>
      dispatch({
        type: ACTION.PURCHASE_DESTINATION,
        payload: res
      })
    )
    .then(() => dispatch(apiCallEnd()))
    .catch(err => {
      dispatch(apiCallError(err.response.data.error));
      throw err;
    });
};

export const purchaseTravelance = data => dispatch => {
  dispatch(beginApiCall());
  return api
    .purchaseDestination(data)
    .then(res =>
      dispatch({
        type: ACTION.PURCHASE_TRAVELANCE,
        payload: res
      })
    )
    .then(() => dispatch(apiCallEnd()))
    .catch(err => {
      dispatch(apiCallError(err.response.data.error));
      throw err;
    });
};

export const purchaseTugo = data => dispatch => {
  dispatch(beginApiCall());
  return api
    .purchaseTugo(data)
    .then(res =>
      dispatch({
        type: ACTION.SET_QUOTE_TUGO,
        payload: res
      })
    )
    .then(() => dispatch(apiCallEnd()))
    .catch(err => {
      dispatch(apiCallError(err.response.data.error));
      throw err;
    });
};
// ============================================================END PURCHASE

// ============================================================START LEAD
// export const setLead = data => dispatch => {
//   dispatch(beginApiCall());
//   return api
//     .setLead(data)
//     .then(res =>
//       dispatch({
//         type: ACTION.SET_LEAD,
//         payload: res
//       })
//     )
//     .catch(err => {
//       dispatch(apiCallError());
//       throw err;
//     });
// };
// ============================================================END LEAD

// ============================================================START HEALTH
export const getHealth = () => dispatch => {
  dispatch(beginApiCall());
  return api
    .getHealth()
    .then(res =>
      dispatch({
        type: ACTION.HELTH_API,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
// ============================================================END HEALTH

// ============================================================START HEALTH
export const getToken = () => dispatch => {
  dispatch(beginApiCall());
  return api
    .getToken()
    .then(res =>
      dispatch({
        type: ACTION.GET_TOKEN,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
// ============================================================END HEALTH

// ============================================================START INSURERS
export const getInsyrers = () => dispatch => {
  dispatch(beginApiCall());
  return api
    .getInsurers()
    .then(res =>
      dispatch({
        type: ACTION.GET_INSURERS,
        payload: res
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
// ============================================================END INSURERS

// ============================================================START INSURERS
export const getPolicy = (data, type) => dispatch => {
  dispatch(beginApiCall());
  return api
    .searchPolicy(data, type)
    .then(res => {
      dispatch({
        type: ACTION.GET_POLICY,
        payload: res
      });
    })
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
// ============================================================END INSURERS
