import React from 'react'

const VideoModal = ({}) => {
  return (
    <video controls={true} autoPlay={true} style={{width: '100%'}}>
      <source src='./videos/learnmore.m4v' type='video/mp4'></source>
    </video>
  )
}

export default VideoModal
